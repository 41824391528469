
import { Sparklines, SparklinesLine, SparklinesReferenceLine, SparklinesCurve, SparklinesNormalBand } from 'react-sparklines';

interface MicroChartProps {
  uid:string
  data: any
}

const MicroChart: React.FC<MicroChartProps> = (props) => {

  let data: number[] = [];

  if (Array.isArray(props.data)) {
    data = props.data.map((dataPoint:any) => parseFloat(dataPoint.Value));
  }

  return (
    <div>
    <Sparklines data={data} limit={12} width={100} height={20} margin={2} >
      <SparklinesCurve color="blue" style={{ strokeWidth: "0.3", fill: "#40c0f5" }} />
      <SparklinesReferenceLine type="avg" style={{ strokeWidth: "0.3", stroke: 'grey', strokeOpacity: .75, strokeDasharray: '2, 2' }}/>
    </Sparklines>
    </div>
  );
}

export default MicroChart;

