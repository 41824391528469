/**
PEAK REVEUNE
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
// Remove the duplicate import statement for the 'Box' component
// import Box from @mui
import Typography from "@mui/material/Typography";


// Material Dashboard 2 PRO React TS components
// Remove the duplicate import statement for the 'Box' component
// import Box from "components/Box";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { ReactHTMLElement, useContext, useEffect, useState } from "react";
import Button from "assets/theme/components/button";
import Link from "@mui/material/Link";

import { Box, Grid, Switch, Table } from '@mui/material';

import { getHeaders } from "../../../../auth/authService";

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ResponsiveLine } from '@nivo/line'
import MDButton from "components/MDButton";

const PortfolioReport: React.FC = () => {  

  const [portfolioHistory, setPortfolioHistory] = useState<any>([])
  const [occupancyData, setOccupancyData] = useState<any>([])
  const [grossRevenueData, setGrossRevenueData] = useState<any>([])
  const [variableCosts, setVariableCosts] = useState<any>([])
  const [grossRevenueChange, setGrossRevenueChange] = useState<any>([])
  const [occupancyChange, setOccupancyChange] = useState<any>([])
  const [variableCostChange, setVariableCostChange] = useState<any>([])
  const [startDate, setStartDate] = useState<Date>(null)
  const [refreshData, setRefreshData] = useState<any>(null)

  function resultsArrow(item:any): any {
    if (item>0) {
      return(
        <ArrowUpwardIcon style={{ verticalAlign: 'middle', fontWeight: 'bold', color: 'green' }} />
      )
    }else if (item<0) {
      return(
        <ArrowDownwardIcon style={{ verticalAlign: 'middle', fontWeight: 'bold', color: 'red' }} />
      )
    }else{
      return(
        ""
      )
    }
  }

  function resultsRow(item:any, key:number): any {
    return(
      <tr style={{ backgroundColor: '#f0f0f0' }}>
        <td style={{padding: '10px'}}>{item.PropertyName}</td>
        <td>{item.Prefix}{parseFloat(item.Value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.Suffix}</td>
        {item.PercentageChange?  
          (
            <td align="center">
              {resultsArrow(item.PercentageChange)}
              {Math.abs(parseFloat(item.PercentageChange)).toFixed(0)}%
            </td>
          ):(<td></td>)
        }
      </tr>
    )
  }

  function summaryRows(portfolioData:any):any  {
    //console.log("portfolioData:", portfolioData)
    return (
      <Card>
        <Box py={2} px={3}>
          <Typography variant="h4" fontWeight="medium">
            {portfolioData.ComponentName}
          </Typography>
        </Box>
        <Box>
          <Grid container p={1}>
            <Grid item lg={3} sm={5} p={2}>
              <Box p={2} border={1} borderRadius={3} style={{ boxShadow: '3px 3px 5px 6px #ccc'}}>
                <Typography variant="h5" fontWeight="medium">
                  Highest
                </Typography>
                <Grid container>
                  <Grid item lg={12} sm={12}>
                    <Table style={{ backgroundColor: 'white', borderCollapse: 'separate', borderSpacing: '0 10px', fontSize: 12}}>
                      {portfolioData.TopPerformers.map((item:any, key:number) => {
                        return(
                          resultsRow(item, key)
                      )})}
                    </Table>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={3} sm={5} p={2}>
              <Box p={2} border={1} borderRadius={3} style={{ boxShadow: '3px 3px 5px 6px #ccc'}}>
                <Typography variant="h5" fontWeight="medium">
                  Lowest
                </Typography>
                <Grid container>
                  <Grid item lg={12} sm={12}>
                    <Table style={{ backgroundColor: 'white', borderCollapse: 'separate', borderSpacing: '0 10px', fontSize: 12}}>
                      {portfolioData.WorstPerformers.map((item:any, key:number) => {
                        return(
                          resultsRow(item, key)
                      )})}
                    </Table>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={3} sm={5} p={2}>
              <Box p={2} border={1} borderRadius={3} style={{ boxShadow: '3px 3px 5px 6px #ccc'}}>
                <Typography variant="h5" fontWeight="medium">
                  Increasers
                </Typography>
                <Grid container>
                  <Grid item lg={12} sm={12}>
                    <Table style={{ backgroundColor: 'white', borderCollapse: 'separate', borderSpacing: '0 10px', fontSize: 12}}>
                      {portfolioData.TopMovers.map((item:any, key:number) => {
                        return(
                          resultsRow(item, key)
                      )})}
                    </Table>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={3} sm={5} p={2}>
              <Box p={2} border={1} borderRadius={3} style={{ boxShadow: '3px 3px 5px 6px #ccc'}}>
                <Typography variant="h5" fontWeight="medium">
                  Decreasers
                </Typography>
                <Grid container>
                  <Grid item lg={12} sm={12}>
                    <Table style={{ backgroundColor: 'white', borderCollapse: 'separate', borderSpacing: '0 10px', fontSize: 12}}>
                      {portfolioData.WorstMovers.map((item:any, key:number) => {
                        return(
                          resultsRow(item, key)
                      )})}
                    </Table>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    )
  }

  function getVars() { 
     //get the cid from local storage
    const cid = sessionStorage.getItem("company_id")
    return cid
  }

  function setStartDateValue(weekNum:number):String {
    
    let localStartDate
    //check if there is a value in startDate
    if (weekNum===0) {
      //first time so set the date to the start of the week
      localStartDate = new Date()
      console.log("Day is :", localStartDate.getDay())
      if(localStartDate.getDay()<=3){
        
        localStartDate.setDate(localStartDate.getDate() - 3)
      }
    }else{
      localStartDate = startDate
    }

    //adjust the date based on the weekNum.  Can be 0, -1 or 1
    //localStartDate.setDate(localStartDate.getDate() - (weekNum * 7))
    localStartDate.setDate(localStartDate.getDate() - localStartDate.getDay())

    setStartDate(localStartDate)
    
    return encodeURI(localStartDate.toISOString().split('T')[0])
  }


  useEffect(() => {

    //can't rely on async setup
    const cid = getVars()

    //Verify user has permissions to view the portfolio report
    //TODO
    const uid = sessionStorage.getItem("user_id")

    const loadData = async (cid:string) => {
      
      //Check the startDate has been set
      let queryDate: String = ""
      console.log("startDate:", startDate)
      if(startDate===null){
        console.log("null startDate:")
        queryDate = setStartDateValue(0)
      }else{
        console.log("Not null startDate:")
        queryDate = encodeURI(startDate.toISOString().split('T')[0])
      }

      try {
        console.log("queryDate:", queryDate)
        const headers = await getHeaders()
        const response = await fetch(`${process.env.REACT_APP_API_URL}/report/portfolio/${cid}/${"2024-04-01"}`, {
          mode: 'cors',
          method: 'GET', 
          headers: headers,
        })
        const data = await response.json();
        console.log("history:", data)

        if(!data.errors){
          //Process Return Data for all reports
          setPortfolioHistory(data.historyData)
          setOccupancyData(data.totals.occupancyTotal)
          setGrossRevenueData(data.totals.grossRevenueTotal)
          setVariableCosts(data.totals.variableCostTotal)
          setGrossRevenueChange(data.totals.grossRevChange)
          setOccupancyChange(data.totals.occupancyChange)
          setVariableCostChange(data.totals.variableCostChange)

        }else{
          //setError("There was an issue retrieving reports")
          throw new Error(data.errors);
        }

      } catch (error) {
        console.error("Error:", error)
      }
    }

    if(cid!==null){
      loadData(cid)
    }

  }, [])


  function getLink(item:any, linkText:string, cid:string): JSX.Element{
      return <Link href={`/reports/view?rid=${item.UID}&cid=${cid}`} color="Grey">{linkText}</Link>
  }

  function getEndDate():String {
    const endDate = new Date()
    endDate.setDate(startDate.getDate() + 7)
    return endDate.toLocaleDateString()

  }

  return (
    <DashboardLayout>

    <Grid container>
      <Grid item lg={12} sm={12}>
        <Box p={2}>
          <Typography variant="h5" fontWeight="medium">
            Portfolio 
          </Typography>
          <Typography variant="body2" color="text">
            Weekly Portfolio report
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={12} sm={12}>
        <Grid container>
          {/* <!-- Row 1 --> */}
          <Grid item lg={4} sm={4} px={1}>
            <Card>
              <Box p={2}>
                <Typography variant="h3" color="text">
                  Key Performance Indicators
                </Typography>
              </Box>
            <Box p={2}>
              <Grid container py={1}>
                <Grid item lg={7} sm={7}>
                  <Typography variant="h4" fontWeight="medium">
                  Average Occupancy
                  </Typography>
                </Grid>
                <Grid item lg={3} sm={3}>
                  <Typography variant="h4" fontWeight="medium" align="right">
                  {occupancyData}%
                  </Typography>
                </Grid>
                <Grid item lg={2} sm={2}>
                  <Typography variant="h4" fontWeight="medium" align="right">
                  {resultsArrow(occupancyChange)}{Math.abs(occupancyChange)}%
                  </Typography>
                </Grid>                
              </Grid>
              <Grid container py={1}>
                <Grid item lg={7} sm={7}>
                  <Typography variant="h4" fontWeight="medium">
                  Gross Revenue
                  </Typography>
                </Grid>
                <Grid item lg={3} sm={3}>
                  <Typography variant="h4" fontWeight="medium" align="right">
                  ${grossRevenueData.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  </Typography>
                </Grid>
                <Grid item lg={2} sm={2}>
                  <Typography variant="h4" fontWeight="medium" align="right">
                  {resultsArrow(grossRevenueChange)}{Math.abs(grossRevenueChange)}%
                  </Typography>
                </Grid>                   
              </Grid>
              <Grid container py={1}>
                <Grid item lg={7} sm={7}>
                  <Typography variant="h4" fontWeight="medium">
                  Variable Expenses
                  </Typography>
                </Grid>
                <Grid item lg={3} sm={3}>
                  <Typography variant="h4" fontWeight="medium" align="right">
                  ${variableCosts.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  </Typography>
                </Grid>
                <Grid item lg={2} sm={2}>
                  <Typography variant="h4" fontWeight="medium" align="right">
                  {resultsArrow(variableCostChange)}{Math.abs(variableCostChange)}%
                  </Typography>
                </Grid>                 
              </Grid>
            </Box>
            </Card>
          </Grid>
          <Grid item lg={8} sm={8} px={1}>
            <Card>
            <Grid container>
                <Grid item lg={4}>
                  <Typography variant="h6" fontWeight="medium">
                    Start Date:&nbsp;
                    {startDate && startDate.toLocaleDateString()}
                  </Typography>
                </Grid>
                <Grid item lg={4}>
                  <Typography variant="h6" fontWeight="medium">
                    End Date:&nbsp;
                    {startDate && getEndDate()}
                  </Typography>
                </Grid>
                <Grid item lg={4} container justifyContent="flex-end" gap={2}>
                    <MDButton  
                      color="info" 
                      size="medium"
                      iconOnly={true}
                      onClick={() => setStartDateValue(-1)}
                      >
                      <ArrowBackIcon/>
                    </MDButton>                  
                    <MDButton      
                      color="info" 
                      size="medium"
                      iconOnly={true}
                      onClick={() => setStartDateValue(1)}
                      >
                      <ArrowForwardIcon/>
                    </MDButton>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {/* <!-- Row 2 --> */}

          {portfolioHistory && portfolioHistory.map((item:any, index:number) => {
            return (
                <Grid item lg={12} sm={12} key={index}>
                    <Box px={1} py={2}>
                        {summaryRows(item)}
                    </Box>
                </Grid>
              )
          })}




        </Grid>
        
      </Grid>
    </Grid>


      <Footer />
    </DashboardLayout>
  );
}

export default PortfolioReport;

