

import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";

import { Icon } from "@mui/material";
import LineChartComponent from '../../../Charts/LineCharts/NivoLine'; // Adjust the path based on your file structure

interface DateValue {
  date: string;
  value: number;
}

// Declaring prop types for DefaultStatisticsCard
interface Props {
  title: string;
  count: string | number;
  variance?: {
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "white";
    value: string | number;
  };
  target: string | number;
  color: string;
  previous?: {
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "white";
    value: string | number;
  };
  chartData?: DateValue[];
  chartLabel?: string
  size?:number
  //icon: ReactNode;
  // [key: string]: any;
}

const BarStatisticsCard: any = React.memo(({
   title, count, variance, color, target, previous, chartData, chartLabel, size }: Props
   ) => {
  
  console.log("inside bar statistics:", chartData, "-", chartLabel);
  return (
    <Card>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={size===4?12:4}>
            <Grid container>
              <Grid item lg={12}>
                <MDBox textAlign="left" lineHeight={1.25} alignItems="left">
                  <MDTypography variant="button" fontWeight="bold" color="text">
                    <h3>{title}</h3>
                  </MDTypography>

                </MDBox>
              </Grid>
            </Grid>

            <MDBox lineHeight={1} width="100%">
              <MDTypography variant="h5" fontWeight="bold" color={color}>
                {count}
              </MDTypography>
              <MDTypography variant="button" fontWeight="bold" color={variance.color}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="secondary"
                >
                  Comparision to target: 
                </MDTypography>
                &nbsp;{target}
              </MDTypography>
            </MDBox>
            <MDBox lineHeight={1} width="100%">
              <MDTypography variant="button" fontWeight="bold" color={variance.color}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="secondary"
                >
                  Comparison to previous period: 
                </MDTypography>
                &nbsp;{variance.value}
              </MDTypography>
            </MDBox>
            <MDBox lineHeight={1} width="100%">
              <MDTypography variant="button" fontWeight="bold" color={previous.color}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="secondary"
                >
                  Same period last year:
                </MDTypography>
                &nbsp;{previous.value}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={8}>
          {chartData &&
            <LineChartComponent id={title} chartData={chartData} chartLabel={chartLabel} />
          }
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
});

// Setting default values for the props of DefaultStatisticsCard
BarStatisticsCard.defaultProps = {
  percentage: {
    color: "success",
    value: "",
    label: "",
  },
  icon: null,
};

export default BarStatisticsCard;
