import { Autocomplete, Card, Divider, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import * as MuiIcons from '@mui/icons-material';
import { Key, useEffect, useState } from "react";
import {getHeaders} from "../../../../auth/authService";
import { useSearchParams } from "react-router-dom";
import { 
    getPropertyDetails, 
} from "lib/dashboard";
import LoadingSpinner from "components/Spinner";
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import MDButton from "components/MDButton";
import Link from "@mui/material/Link";
import { useCache } from 'lib/CacheContext'; 
import PieChart from "./components/NivoPie";
import BarChart from "./components/NivoBar";
interface DateValue {
  date: string;
  value: number;
}

var dashboardCache = new Array()
function getCacheKey(did:string, fdate:Date,tdate:Date): string {
  console.log("cache date: ", fdate)
  if(tdate){
      return `${did}:${fdate.toISOString().split('T')[0]}:${tdate.toISOString().split('T')[0]}`
  }else{
      return ""
  }
}

function Reservations(): JSX.Element {
    const cache = useCache();

    const dollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

    const percentage = new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    function formatValue(prefix: string, value: number): string  {
        switch (prefix) {
            case "$":
                return dollar.format(value)
                break;
            case "%":
                return percentage.format(value)
                break;      
            default:
                return value.toString()
                break;
        }
    }

    type ReservationsType = {
      resCount: number,
      totalDays: number,
      sumBySourceName: Record<string, number>,
      chartData: [],
      reservationIDs: [],
      sumByIATA: []
    }
 
    const [startDate , setStartDate] = useState<Date>()
    const [endDate , setEndDate] = useState<Date>()
    const [startDisplayDate , setStartDisplayDate] = useState<any>(null)
    const [endDisplayDate , setEndDisplayDate] = useState<any>(null)
    const [headers,setHeaders] = useState<HeadersInit>()
    const [searchParams] = useSearchParams();
    const pid = searchParams.get("propertyId") 
    const cid = searchParams.get("companyId")
    const sd = searchParams.get("sd")
    const ed = searchParams.get("ed")
    const [isLoading, setIsLoading] = useState(true);
    const [propSetup, setPropSetup] = useState<any>()
    const [refresh, setRefresh] = useState<any>()
    const [reservations, setReservations] = useState<ReservationsType>(null)
    const [property, setProperty] = useState<any>(false)
    const [iataArray, setIataArray] = useState<{
      [key: string]: number;
    }>()

    // 1) Load Property and Headers
    useEffect(() => {
      const setup = async () => {
        if(sd&&ed){
          setStartDate(new Date(sd))
          setEndDate(new Date(ed))
          setStartDisplayDate(new Date(sd))
          setEndDisplayDate(new Date(ed))
        }else{
            setPreviousWeekDates()
        }
        console.log("startDate: ", startDate)
        const hdrs = await getHeaders()
        setHeaders(hdrs)
        setProperty(await getPropertyDetails(pid,cid,hdrs))
        
        setPropSetup(true)
      }
      setup()
      
    },[])

    // 2) Get Dashboard components and data
    useEffect(() => {
      console.log("startDate: ", startDate)
      console.log("endDate", endDate)
      const fetchData = async () => {
          setIsLoading(true)
          const reservations = await getDashboard();
          if(reservations){
              setReservations(reservations);
              console.log("Reservations loaded: ", reservations)
          }
          setIataArray(reservations.sumByIATA);

          setIsLoading(false)
      };
      if(startDate!=null){fetchData();}
      
    }, [refresh,propSetup]);
    
    const getDashboard = async (): Promise<any>=> {
        //Check if the data is in the cache
        const cacheKey = getCacheKey(cid, startDate, endDate)
        const keyExists = cache.hasOwnProperty(cacheKey);

        if(keyExists){
            console.log("Hash used")
            return cache[cacheKey]
        } else {
            try{
              const body = JSON.stringify({
                  "fromDate": startDate.toISOString().split('T')[0],
                  "toDate": endDate.toISOString().split('T')[0],
                  "propertyID": pid,
              });

             const response = await fetch(`${process.env.REACT_APP_API_URL}/rms/reservations`, {
                  mode: 'cors',
                  method: 'post',
                  body: body,
                  headers: headers,
              })
      
              const data = await response.json();
              cache[cacheKey] = data;
              return data
        
            } catch (error) {
              console.log("Error retreiving Reservations", error)
            }
        }
    }

      //Extract the start and end dates when changing the Sales dropdown
      // This needs to be Monday to Sunday
      function setSalesDates(priorDays: number, end?: Date): void{

        const endDate = end ? dayjs(end) : dayjs();
        const priorDate = endDate.subtract(priorDays, 'd') 

        setStartDate(priorDate.toDate());
        setEndDate(endDate.toDate());
        
        setStartDisplayDate(priorDate)
        setEndDisplayDate(endDate)

        console.log("Dates Set: ", priorDate.toDate())
    }

    const setPreviousWeekDates: any = async () => {
      const today = new Date();
      const previousSunday = new Date(today);
      previousSunday.setDate(today.getDate() - today.getDay());
      setSalesDates(7, previousSunday)
    }

      function setManualDates() {
        setStartDate(startDisplayDate)
        setEndDate(endDisplayDate) 
        setRefresh(new Date())
      }


    function setWeekEnd(newValue: any): void {
        const weekEnd = new Date(newValue)
        setSalesDates(7,weekEnd)
        setRefresh(new Date())
    }

    return(
        <DashboardLayout>
        <DashboardNavbar />
        
        <Grid container alignItems="center" >
            <Grid item lg={6}>
                <MDBox py={1} >
                    <h2>{property.name}</h2>
                </MDBox>
            </Grid>
            <Grid item lg={6}>
                <Card>
                <Grid container>
  
                    <Grid item lg={5}>
                        <MDBox p={2}>
                        <DatePicker 
                              format="DD-MM-YYYY"
                              label="From"
                              value={dayjs(startDisplayDate)}
                              onChange={(newValue) => setStartDisplayDate(newValue)}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item lg={5} >
                        <MDBox p={2}>
                        <DatePicker 
                              format="DD-MM-YYYY"
                              label="To"
                              value={dayjs(endDisplayDate)}
                              onChange={(newValue) => setWeekEnd(newValue)}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item lg={2} >
                        <MDBox p={2}>
                        <MDButton
                            component={Link}
                            to=""
                            variant="gradient"
                            color="light"
                            fullWidth
                            onClick={() => {
                                setManualDates();
                              }}
                          >
                            GO&nbsp;
                        </MDButton>
                        </MDBox>
                    </Grid>
                </Grid>
                </Card>
            </Grid>
        </Grid>
        {isLoading ? <LoadingSpinner /> : (
          
          <Grid container alignItems="top">
            <Grid item lg={12} px={2}>
              <MDTypography py={2}>
                  <h3>Reservations</h3>
              </MDTypography>
            </Grid>

            <Grid item lg={4} height="100%">
              <Grid container py={2}>
                <Grid item lg={12} >
                  <Card>
                    <MDBox textAlign="left" lineHeight={1.25} alignItems="left" px={3}>
                      <Grid container alignItems="left">
                        
                        <Grid item lg={12} >
                          <MDTypography py={2} >
                          <h5>Reservation Summary</h5>
                          
                          <Divider/>
                          <MDBox fontSize={16}>
                            <p>Total Reservations: {reservations.resCount}</p>
                            <p>Total Days: {reservations.totalDays}</p>
                          </MDBox>
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item lg={12} py={2}>
                  <Card>
                    <MDBox textAlign="left" lineHeight={1.25} alignItems="left" px={3}>
                      <Grid container alignItems="left">
                        <Grid item lg={12} >
                          <MDTypography py={2}>
                          <h5>GDS by SiteMinder source</h5>
                          <Divider/>
                          {Object.entries(iataArray).map(([key, value]) => {
                            if (key !== 'undefined' && key !== 'null') {
                              return (
                                <MDBox fontSize={16}>
                                  <p>{key}: {value}</p>
                                </MDBox>
                              );
                            }
                            return null;
                          })}
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </MDBox>
                </Card>
                </Grid>
              </Grid>
              
              
            </Grid>
          
            <Grid item lg={8} p={2}>
                {reservations.sumBySourceName && (
                  <PieChart channelData={reservations.sumBySourceName} />
                )}
            </Grid>
            <Grid item lg={12} p={2}>
                {reservations.sumBySourceName && (
                  <BarChart channelData={reservations.sumBySourceName} />
                )}
            </Grid>
          </Grid>
          
        )
    }
    </DashboardLayout>
    )   
}

export default Reservations;


