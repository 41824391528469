

import { useContext, useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/reports/new-report/schemas/validations";
import form from "layouts/pages/reports/new-report/schemas/form";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { getHeaders } from "auth/authService";
import {AppContext, UserData} from 'lib/appContext';
import ReportForm from "./components/ReportForm"


function NewSchedReport(): JSX.Element {

  const [searchParams] = useSearchParams();
  const companyID = searchParams.get("companyId")
  const [buttonText, setButtonText] = useState("Save")

  const { formId, formField } = form;
  const currentValidation = validations[0];
  const navigate = useNavigate();
  const [properties, setProperties] = useState<{ key: string; value: string }[]>([]);
  const [components, setComponents] = useState<{ key: string; value: string }[]>([]);

  const sleep = (ms: any) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  

  //Retrieve all Properties for company
  useEffect(() => {


    //Load all of the Componenets available for the report
    const loadComponents = async () => {
      const headers = await getHeaders();
      //console.log("Headers: ", headers)
      const response = await fetch(`${process.env.REACT_APP_API_URL}/components`, {
        mode: 'cors',
        method: 'GET', 
        headers: headers,
      })
      const data = await response.json()
      const components:any = []
      if(data!=null){
        data.map((item:any)=>{
          //console.log(item)
          components.push({
             key: item.uid,
             value: item.name
          })
        })
        //sort components by name
        components.sort((a:any, b:any) => (a.value > b.value) ? 1 : -1)
        setComponents(components)
      }
    }
    //loadProperties()
    loadComponents()
  },[])

  return (
    <DashboardLayout>
      <MDBox py={3} mb={20} >
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={12}>
              {(properties&&components)&&
                <ReportForm properties={properties} components={components} companyId={companyID}/>
              }
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewSchedReport;
