/**
PEAK REVEUNE
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { Component, ReactComponentElement, ReactHTMLElement, useContext, useEffect, useRef, useState } from "react";
import MDButton from "components/MDButton";
import { Link, useParams } from 'react-router-dom';

import { getHeaders } from "auth/authService";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MicroChart from "../components/MicroChart"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { resourcePerformance } from "../components/HouseKeeperPerfHistory";
import form from "../../new-report/schemas/form";

const TemplateReport: React.FC = () => {  

  type MicroChart = {
    uid: string,
    data: any
  }

  const { companyId, propertyId, templateId } = useParams();
  const [report, setReport] = useState<any>(null) 
  const [reportData, setReportData] = useState<Array<ReportData>>([]);
  const [microCharts, setMicroCharts] = useState<MicroChart[]>([])
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [templateName, setTemplateName] = useState<string>("")
  const [PropertyName, setPropertyName] = useState<string>("")
  const [startDate, setStartDate] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")
  const [weekNum, setWeekNum] = useState<number>(11)
  const [AIData, setAIData] = useState<string>("")
  const [analysis, setAnalysis] = useState<string>("")

  type ReportData = { 
    uid: string,
    key: string,
    data: any[]
  }

  useEffect(() => {

    const loadReport = async (companyId: string, propertyId:string,templateId:string) => {
      try {
        const headers = await getHeaders()

        //Load the Property details
        const queryParams = `?propertyId=${propertyId}&companyId=${companyId}`
        const property: any  = await fetch(`${process.env.REACT_APP_API_URL}/property${queryParams}`, { 
          mode: 'cors',
          method: 'GET', 
          headers: headers,
        })
        const pData = await property.json();
        setPropertyName(pData.name)
            
        //retrieve template data
        const template = await fetch(`${process.env.REACT_APP_API_URL}/reports/template/${templateId}`, {
          mode: 'cors',
          method: 'GET', 
          headers: headers,
        })
        const tData = await template.json();
        //console.log("report template:", tData)
        setTemplateName(tData.template.description)
    
        const selectedComponents = JSON.parse(tData.template.selectedComponents)
        //console.log("selectedComponents:", selectedComponents)
    
        const promises = selectedComponents.map(async (item: any) => {

          //retrive history data for each component in the template
          const body = JSON.stringify({
            "companyId": companyId,
            "propertyId": propertyId,
            "componentId": item.key,
            "numberOfWeek": 12
          });
          
          const hResponse = await fetch(`${process.env.REACT_APP_API_URL}/gethistory`, {
            mode: 'cors',
            method: 'POST', 
            body: body,
            headers: headers,
          })
          const hData = await hResponse.json();
          //console.log("history data:", hData)
          
          setReportData((prevReportData: any) => [
            ...prevReportData,
            {uid: item.value, key: item.key, data: hData.history, sort: item.sort, prefix: hData.component[0].prefix, suffix: hData.component[0].postfix}
          ]);

          //console.log("hData:", hData)
        })
    
        await Promise.all(promises);
        
        //Sort the components by sort order
        setReportData((prevReportData: any) => prevReportData.sort((a: any, b: any) => a.sort - b.sort))
        
        setDataLoaded(true);
      } catch (error) {
        console.error("Error:", error)
      }
    }
    
    loadReport(companyId, propertyId,templateId)
    
  
  }, [])

  async function getAIResponse(): Promise<void> {
    
    //reformat reportData into a format that can be sent to the AI API
    const reformattedData = reportData.map((item: ReportData) => ({
      UID: item.uid,
      Data: item.data.map((dataPoint: any) => ({
        Date: dataPoint.EndDate,
        Value: dataPoint.Value
      }))
    }));

    const headers = await getHeaders()
    
    const message = JSON.stringify(reformattedData);

    console.log("message:", message)

    const hResponse = await fetch(`${process.env.REACT_APP_API_URL}/report/analysis`, {
      mode: 'cors',
      method: 'POST', 
      body: message,
      headers: headers,
    })
    const hData = await hResponse.json();
    console.log("hData:", hData)
    setAnalysis(hData)
  }

  function changeWeek(shift:number) {
    if(shift === -1 && weekNum === 1) return
    if(shift === 1 && weekNum === 11) return
    setWeekNum(weekNum + shift)
  }

  function statistics(data: any, prefix: string, suffix: string) {
    const total = data.reduce((sum: number, dataPoint: any) => sum + parseFloat(dataPoint.Value), 0);
    const avg = (total / data.length).toFixed(2);
    const maximum = Math.max(...data.map((dataPoint: any) => parseFloat(dataPoint.Value))).toFixed(2);
    const minimum = Math.min(...data.map((dataPoint: any) => parseFloat(dataPoint.Value))).toFixed(2);
    return (
      <Box>
        <Typography variant={"body2"}>Maximum: {formatData(parseFloat(maximum),prefix,suffix)}</Typography>
        <Typography variant={"body2"}>Minimum: {formatData(parseFloat(minimum),prefix,suffix)}</Typography>
        <Typography variant={"body2"}>Average: {formatData(parseFloat(avg),prefix,suffix)}</Typography>
      </Box>
    );
  }

  function formatData(data:number, prefix:string, suffix:string) {
    return `${prefix}${data.toLocaleString('en-US', { minimumFractionDigits: 2 })}${suffix !== '%' ? "  " + suffix : suffix}`
  }

  function dataTable(data: any)  {
    return (
      <Box key={`dataBox-${data.key}`}>
      <TableContainer >
        <Table >
          <TableBody>
            <TableRow >
              <TableCell ><Typography variant={"h6"}>Current Period</Typography></TableCell>
              <TableCell ><Typography variant={"h6"}>Previous Period</Typography></TableCell>
              <TableCell ><Box display="flex" alignItems="center">
                  <Typography variant="h6">History</Typography>
                </Box>
              </TableCell>
              <TableCell ><Typography variant={"h6"}>Summary</Typography></TableCell>
            </TableRow>
            <TableRow key={data.uid}>
              <TableCell >
                  {formatData(parseFloat(data.data[weekNum].Value), data.prefix, data.suffix)}  
                  {/* {data.prefix}{parseFloat(data.data[weekNum].Value).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                  {data.suffix !== '%' ? `  ${data.suffix}` : `${data.suffix}`} */}
                </TableCell>
                <TableCell >
                  {formatData(parseFloat(data.data[weekNum-1].Value), data.prefix, data.suffix)}
                   {/* {data.prefix}{parseFloat(data.data[weekNum-1].Value).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                  {data.prefix}{parseFloat(data.data[weekNum-1].Value).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                  {data.suffix !== '%' ? `  ${data.suffix}` : `${data.suffix}`} */}
                </TableCell>
              <TableCell style={{ width: '25%' }}>
                <Link to={`/reports/view/${data.key}/${propertyId}`} color="Grey">
                  <MicroChart {...data} />
                </Link>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="caption">
                    {new Date(data.data[0].StartDate).toLocaleDateString()}
                  </Typography>
                  <Typography variant="caption">
                    {new Date(data.data[data.data.length - 1].EndDate).toLocaleDateString()}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell style={{ width: '25%' }}>
                {statistics(data.data, data.prefix, data.suffix)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
    );
  }

  

  return (
    <DashboardLayout>
      <Grid container spacing={3} >
        <Grid item xs={12} md={12} lg={12}>
          <Card >
            <MDBox p={3} lineHeight={1}>
              <Grid container>
                <Grid item lg={6}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {`${PropertyName} - ${templateName}`}
                  </MDTypography>
                </Grid>
                <Grid item lg={2}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Start Date:&nbsp;
                    {dataLoaded && new Date(reportData[0].data[weekNum].StartDate).toLocaleDateString()}
                  </MDTypography>
                </Grid>
                <Grid item lg={2}>
                  <MDTypography variant="h6" fontWeight="medium">
                    End Date:&nbsp;
                    {dataLoaded && new Date(reportData[0].data[weekNum].EndDate).toLocaleDateString()}
                  </MDTypography>
                </Grid>
                <Grid item lg={2} container justifyContent="flex-end" gap={2}>
                  <MDButton      
                           
                    color="info" 
                    size="medium"
                    iconOnly={true}
                    onClick={() => changeWeek(-1)}>
                    <ArrowBackIcon/>
                  </MDButton>                  
                  <MDButton      
                          
                    color="info" 
                    size="medium"
                    iconOnly={true}
                    onClick={() => changeWeek(1)}>
                    <ArrowForwardIcon/>
                  </MDButton>
                </Grid>
              </Grid>

            </MDBox>

          </Card>
        </Grid>


        {/* grid construct for the report data */}
         
        <Grid container p={3}>
          {/* loop through reportData and display a table for each item */}
          {dataLoaded && reportData.map((item: any) => {
            console.log("item:", item)
            return (              
              <Grid item lg={12} md={12} key={`dataTable-${item.key}`}>
              <Box mb={2} px={1} width={'100%'} key={`dataTable-${item.key}`}>
              <Card>
              <MDBox p={2} py={3}>
                <Grid container >
                  <Grid item lg={12}>
                    <Grid container>
                      <Grid item lg={12}>
                        <MDBox textAlign="left" lineHeight={1.25} alignItems="left">
                          <MDTypography variant="button" fontWeight="bold" color="text">
                            <h3>{item.uid}</h3>
                          </MDTypography>
        
                        </MDBox>
                      </Grid>
                      <Grid item lg={12} md={12}>
                        {
                          item.key === "hsjs-kjs7d-bnjsaa-fsds" //TODO: change this to a more robust method instead of hard coded ID
                            ? resourcePerformance(item.data[weekNum].Value)
                            : dataTable(item)
                        }

                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            </Box> 
            </Grid>
           
            );
          })}
          </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <Grid container>
                <Grid item lg={12}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Analysis
                  </MDTypography>
                  <MDTypography variant="body2">
                    {analysis.split('\n').map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                  </MDTypography>
                </Grid>
              </Grid>
              <MDButton 
                color="warning" 
                size="medium"
                onClick={() => getAIResponse()}
                style={{ float: 'right' }}
              >
                Generate Insights
              </MDButton>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}



export default TemplateReport;

