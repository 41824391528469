import React, { useEffect, useState } from 'react';
import TextField, { FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextFieldVariants } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers';
import { Card, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import MDBox from 'components/MDBox';
import { getHeaders } from "auth/authService";
import { useNavigate } from 'react-router-dom';
import {AppContext, UserData} from 'lib/appContext';
import { Margin } from '@mui/icons-material';
import MDButton from 'components/MDButton';


const initialValues: {
  description: string;
  startDate: Date | null;
  endDate: Date | null;
  selectedProperties: { key: string; value: string }[];
  selectedComponents: { key: string; value: string }[];
  companyID: string;
  selectedTemplate: string;
} = {
  description: '',
  startDate: null,
  endDate: null,
  selectedProperties: [],
  selectedComponents: [],
  companyID: '',
  selectedTemplate: '',
};

interface FormProps {
  properties: { key: string; value: string }[];
  components: { key: string; value: string }[];
  companyId: string
}

const ReportForm: React.FC<FormProps> = ({ properties, components, companyId }) =>{
  const navigate = useNavigate();
  useEffect(() => {
    setFormValues({
      ...formValues,
      companyID: companyId,
    });
  },[])
  
  const [formValues, setFormValues] = useState(initialValues);
  //const [company_id, setCompanyID] = useState(companyId)
  const [buttonText, setButtonText] = useState("Generate Report")
  const [reportTemplates, setReportTemplates] = useState<[]>([])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleStartDateChange = (date: Date | null) => {
    setFormValues({
      ...formValues,
      startDate: date,
    });
  };

  const handleEndDateChange = (date: Date | null) => {
    setFormValues({
      ...formValues,
      endDate: date,
    });
  };

  const [selectedProperties, setSelectedProperties] = useState<{ key: string; value: string }[]>([]);

  const handlePropertyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optionValue = event.target.value;
    setSelectedProperties((prevSelectedOptions) => {
      if (prevSelectedOptions.some((option) => option.key === optionValue)) {
        return prevSelectedOptions.filter((option) => option.key !== optionValue);
      } else {
        const property = properties.find((property) => property.key === optionValue);
        if (property) {
          return [...prevSelectedOptions, property];
        } else {
          return prevSelectedOptions;
        }
      }
    });
    setFormValues({
      ...formValues,
      selectedProperties: selectedProperties,
    });
  };

  const [selectedComponents, setSelectedComponents] = useState<{ key: string; value: string }[]>([]);

  const handleComponentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    const optionValue = event.target.value;
    setSelectedComponents((prevSelectedOptions) => {
      if (prevSelectedOptions.some((option) => option.key === optionValue)) {
        return prevSelectedOptions.filter((option) => option.key !== optionValue);
      } else {
        const component = components.find((component) => component.key === optionValue);
        if (component) {
          return [...prevSelectedOptions, component];
        } else {
          return prevSelectedOptions;
        }
      }
    });
    setFormValues({
      ...formValues,
      selectedComponents: components,
    });
  };

  const sleep = (ms: any) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
  });

  function formatDateToYYYYMMDD(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month (0-based index) and pad with '0'
    const day = String(date.getDate()).padStart(2, '0'); // Pad with '0'
    return `${year}-${month}-${day}`;
  }

  const submitForm = async (values: any) => {

    setButtonText("Saving...")
    console.log("saving new report:", values)

    //Convert dates to locale string
    const sDate = formatDateToYYYYMMDD(new Date (values.startDate))
    const eDate = formatDateToYYYYMMDD(new Date (values.endDate))

    //Save property
    const body = JSON.stringify(
      {
        "description": values.description,
        "companyId": values.companyID,
        "startDate": sDate,
        "endDate": eDate,
        "selectedProperties": selectedProperties,
        "selectedComponents": selectedComponents,
        "userId": sessionStorage.getItem('user_id'),
        "selectedTemplate": values.selectedTemplate,
      })
      
    let headers = await getHeaders();
    //headers = {...headers,'X-Amz-Invocation-Type': 'Event'}

    const response = await fetch(`${process.env.REACT_APP_API_URL}/reports`, { 
      mode: 'cors',
      method: 'post',
      body: body,
      headers: headers,
    })

    console.log("response:", response)
    await sleep(2000);
    navigate("/reports")

  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    submitForm(formValues);
    //console.log('Form values:', formValues);

  };

  useEffect(() => {

    const loadReportTemplates = async (cid:string) => {
      try {
        const headers = await getHeaders()
        const response = await fetch(`${process.env.REACT_APP_API_URL}/reports/templates/${cid}`, {
          mode: 'cors',
          method: 'GET', 
          headers: headers,
        })
        const data = await response.json();
        console.log("reports templates:", data)
        const uid = sessionStorage.getItem("user_id")
        console.log("uid:", uid)
        if(!data.errors){
          //Process Return Data for all reports
          setReportTemplates(data)
        }
      } catch (error) {
        console.error("Error:", error)
      }
    }
    loadReportTemplates(companyId)
    
  }, [])

  function ReportTemplateSelect() {
    return (
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="select-template-label">Select a Template</InputLabel>
        <Select onChange={handleTemplateChange}
          value={formValues.selectedTemplate}
          //defaultValue=""
          label="Report Template"
          sx={{
            width: 200,
            height: 44,
          }}>
          {reportTemplates.map((template:{selectedComponents:[], description:string, companyID:string, UID:string, userId:string}) => (
            <MenuItem key={template.UID} value={template.UID}>
              {template.description}
            </MenuItem>
          ))}
        </Select>
        
      </FormControl>
    );
  }

  function handleTemplateChange(event: SelectChangeEvent<string>) {
    setFormValues({
      ...formValues,
      selectedTemplate: event.target.value,
    });
    console.log("formValues:", formValues)
  }

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
          <Grid container >
            <Grid item lg={12}>
              <Typography variant="h5" align="left" gutterBottom>
                New Report
              </Typography>
              <Typography align="left" gutterBottom fontSize={16}>
                Select start and end dates and the properties and components to include in the report
              </Typography>
              <Card>
                <MDBox p={1} px={2}>
                  <Grid container>
                    <Grid item lg={6} p={2}>
                      <TextField
                        fullWidth
                        label="Report Name"
                        name="description"
                        value={formValues.description}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item lg={3} p={2}>
                      <DatePicker 
                        format="DD-MM-YYYY"
                        label="Start Date"
                        value={formValues.startDate}
                        onChange={handleStartDateChange}
                      />
                    </Grid>
                    <Grid item lg={3} p={2}>
                      <DatePicker 
                        format="DD-MM-YYYY"
                        label="End Date"
                        value={formValues.endDate}
                        onChange={handleEndDateChange}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
          <Grid item lg={12} >
            <Grid container>
              <Grid item lg={6} p={2}>
                <Card>
                  <MDBox p={1} px={2}>
                    <Typography variant="h6" align="left" gutterBottom>
                      Report Properties
                    </Typography>
                    <Divider/>
                    <FormGroup>
                    {properties.map((property) => (
                      <FormControlLabel
                        key={property.key}
                        control={
                          <Checkbox
                            checked={selectedProperties.some((option) => option.key === property.key)}
                            onChange={handlePropertyChange}
                            value={property.key}
                          />
                        }
                        label={property.value}
                      />
                      ))}
                    </FormGroup>
                  </MDBox>
                </Card>
              </Grid>
           
              <Grid item lg={6} p={2}>
                <Card style={{ marginBottom: '4px' }}>
                  <MDBox p={1} px={2}>
                    <Typography variant="h6" align="left" gutterBottom>
                          Report Templates
                    </Typography>
                    <ReportTemplateSelect/>  
                  </MDBox>
                </Card>
                <Card>
                  <MDBox p={1} px={2}>
                    <Typography variant="h6" align="left" gutterBottom>
                      Custom Report
                    </Typography>
                    <Divider/>
                    <FormGroup>
                      {components.map((component) => (
                        <FormControlLabel
                          key={component.key}
                          control={
                            <Checkbox
                              checked={selectedComponents.some((option) => option.key === component.key)}
                              onChange={handleComponentChange}
                              value={component.key}
                            />
                          }
                          label={component.value}
                        />
                      ))}
                    </FormGroup>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} py={2}>
            <Box mt={2}>
              <MDButton type="submit" variant="contained" color="dark" style={{ color: 'white' }}>
                {buttonText}
              </MDButton>
            </Box>
          </Grid>
      </form>
    </Container>
  );
};

export default ReportForm;
