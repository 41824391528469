import React from 'react';
import { ResponsiveLine } from '@nivo/line';

interface DateValue {
  date: string;
  value: number;
}

interface Props {
  chartData: DateValue[];
  chartLabel: string;
  id: string;
}

const LineChartComponent: React.FC<Props> = React.memo(({ id, chartData, chartLabel }) => {
  const data = [
    {
      id,
      data: chartData.map(dataPoint => ({
        x: dataPoint.date,
        y: dataPoint.value,
      })),
    },
  ];

  console.log("inside line chart")
  return (
    <div style={{ height: '300px' }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
        xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: false }}
        xFormat="time:%Y-%m-%d"
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        curve="catmullRom"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: '%b %d',
          tickValues: 'every 2 days',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: chartLabel,
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        enableGridX={true}
        enableGridY={true}
        colors={{ scheme: 'category10' }}
        lineWidth={3}
        enablePoints={true}
        enableArea={false}
        areaOpacity={0.2}
        animate={true}
        isInteractive={true}
        pointSize={10}
        pointColor='white'//{{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ theme: 'labels.text.fill' }}
        pointLabelYOffset={-12}
        enableCrosshair={false}
        useMesh={true}
        pointLabel='y'
        //motionStiffness={90}
        //motionDamping={15}
      />
    </div>
  );
});

export default LineChartComponent;
