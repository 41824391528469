// LoadingSpinner.js
import React, { FC } from 'react';
import './LoadingSpinner.css'; // Import your CSS for styling the spinner
import { Container, Grid } from '@mui/material';

//const MDBox: FC<Props> = forwardRef(
const LoadingSpinner: FC = () => {
  return(
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '50vh' }}
    >
    <div className="loading-spinner"></div>
  </Grid>

  ) 
};

export default LoadingSpinner;
