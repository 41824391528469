import React, { useEffect, useState, useRef } from 'react';
import TextField, { FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextFieldVariants } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers';
import { Card, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, List, ListItem, ListItemText, MenuItem, Select } from '@mui/material';
import MDBox from 'components/MDBox';
import { getHeaders } from "auth/authService";
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MDButton from 'components/MDButton';
import { BorderAllRounded } from '@mui/icons-material';


const initialValues: {
  description: string;
  selectedProperties: { key: string; value: string }[];
  selectedComponents: { key: string; value: string }[];
  companyID: string;
  weekRange: number;
  dayToRun: number;
} = {
  description: '',
  selectedProperties: [],
  selectedComponents: [],
  companyID: '',
  weekRange: 0,
  dayToRun: 0
};

interface FormProps {
  properties: { key: string; value: string }[];
  components: { key: string; value: string }[];
  companyId: string
}



const ReportForm: React.FC<FormProps> = ({ properties, components, companyId }) =>{
  const navigate = useNavigate();
  const [localComponets, setLocalComponents] = useState<{ key: string; value: string }[]>(Array.from(components));

  
  useEffect(() => {
    setFormValues({
      ...formValues,
      companyID: companyId,
    });
    setLocalComponents(Array.from(components))
  },[components])
  
  const [formValues, setFormValues] = useState(initialValues);
  const [company_id, setCompanyID] = useState(companyId)
  const [buttonText, setButtonText] = useState("Generate Report")

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleWeekChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setFormValues({
      ...formValues,
      weekRange: parseInt(event.target.value as string),
    });
  };

  const handleDayChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setFormValues({
      ...formValues,
      dayToRun: parseInt(event.target.value as string),
    });
  };


  const [selectedProperties, setSelectedProperties] = useState<{ key: string; value: string }[]>([]);

  const handlePropertyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optionValue = event.target.value;
    setSelectedProperties((prevSelectedOptions) => {
      if (prevSelectedOptions.some((option) => option.key === optionValue)) {
        return prevSelectedOptions.filter((option) => option.key !== optionValue);
      } else {
        const property = properties.find((property) => property.key === optionValue);
        if (property) {
          return [...prevSelectedOptions, property];
        } else {
          return prevSelectedOptions;
        }
      }
    });
    setFormValues({
      ...formValues,
      selectedProperties: selectedProperties,
    });
  };

  const [selectedComponents, setSelectedComponents] = useState<{ key: string; value: string }[]>([]);

  const handleComponentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    const optionValue = event.target.value;
    setSelectedComponents((prevSelectedOptions) => {
      if (prevSelectedOptions.some((option) => option.key === optionValue)) {
        return prevSelectedOptions.filter((option) => option.key !== optionValue);
      } else {
        const component = components.find((component) => component.key === optionValue);
        if (component) {
          return [...prevSelectedOptions, component];
        } else {
          return prevSelectedOptions;
        }
      }
    });
    setFormValues({
      ...formValues,
      selectedComponents: components,
    });
  };

  const sleep = (ms: any) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
  });

  function formatDateToYYYYMMDD(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month (0-based index) and pad with '0'
    const day = String(date.getDate()).padStart(2, '0'); // Pad with '0'
    return `${year}-${month}-${day}`;
  }

  const submitForm = async (values: any) => {

    setButtonText("Saving...")
    console.log("saving new scheduled report:", values)

    //Save property
    const body = JSON.stringify(
      {
        "description": values.description,
        "companyId": values.companyID,
        "selectedProperties": selectedProperties,
        "selectedComponents": selectedComponents,
        "userId": sessionStorage.getItem('user_id'),
        "startDay": values.weekRange,
        "scheduledDay": values.dayToRun, 
        "endDay": 0,
      })
      
    let headers = await getHeaders();

    const response = await fetch(`${process.env.REACT_APP_API_URL}/reports/schedule`, { 
      mode: 'cors',
      method: 'post',
      body: body,
      headers: headers,
    })

    console.log("response:", response)
    await sleep(2000);
    navigate("/reports")

  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    submitForm(formValues);
    //console.log('Form values:', formValues);

  };

  // const dragItem = useRef();
  // const dragOverItem = useRef();
  // const dragStart = (e: any, item: any) => {
  //   console.log('drag starting', item);
  //   dragItem.current = item;
  // }
  // const dragEnter = (e: any, item: any) => {
  //   console.log('Entering drag', item);
  //   dragOverItem.current = item;
  // }
  // const drop = (e: any, item: any) => {
  //   console.log('dropping', item);
  //   console.log("Target:", e)
  //   alert(e.target.id)
  //   //check that the item is dropped in the selected components list
  //   if(e.target.id == 'selectedComponentsBox') alert('dropped in selected components box');
  //   const dragItemIndex = selectedComponents.indexOf(dragItem.current);
  //   const dragOverItemIndex = selectedComponents.indexOf(dragOverItem.current);
  //   selectedComponents.splice(dragItemIndex, 1, dragOverItem.current);
  //   selectedComponents.splice(dragOverItemIndex, 1, dragItem.current);
  //   setSelectedComponents(selectedComponents);
  //   console.log('selectedComponents', selectedComponents);
  // }

  function WeekSelect() {
    const [day, setDay] = React.useState('');
    const days = ["Mon-Sun", "Tues-Mon", "Wed-Tues", "Thurs-Wed", "Fri-Thurs", "Sat-Fri", "Sun-Sat"];
  
    return (
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="select-helper-label">Day range</InputLabel>
        <Select value={formValues.weekRange.toString()} onChange={handleWeekChange}
          defaultValue="0"
          label="Day range"
          sx={{
            width: 200,
            height: 44,
          }}>
          {days.map((day, index) => (
            <MenuItem key={index} value={index}>
              {day}
            </MenuItem>
          ))}
        </Select>
        
      </FormControl>
    );
  }

  function DaySelect() {
    const [day, setDay] = React.useState('');
    const days = ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"];
  
    return (
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="select-day-label">Day to Run</InputLabel>
        <Select value={formValues.dayToRun.toString()} onChange={handleDayChange}
          defaultValue="0"

          label="Day To Run"
          sx={{
            width: 200,
            height: 44,
          }}>
          {days.map((day, index) => (
            <MenuItem key={index} value={index}>
              {day}
            </MenuItem>
          ))}
        </Select>
        
      </FormControl>
    );
  }

  const [characters, updateCharacters] = useState(components);

  function handleOnDragEnd(result: { destination: { index: number; droppableId: string}; source: { index: number; }; draggableId: string;}) {
    
    console.log("handleOnDragEnd result:", result)
    if (!result.destination) return;

    if(result.destination.droppableId == 'selectedComponents') {
      console.log("dropped in selected components")
          // check if the dropped item is in the selected components list and if not then add it to the list
      if(!selectedComponents.some((option) => option.key === result.draggableId)) {
        const component = localComponets.find((component) => component.key === result.draggableId);
        if (component) {
          setSelectedComponents([...selectedComponents, component]);
        }
        //remove component from components list
        const items = Array.from(localComponets);
        const [reorderedItem] = items.splice(result.source.index, 1);
        setLocalComponents(items);

      }else{
        //reorder if required
        console.log("reorder selected components")
        const items = Array.from(selectedComponents);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        setSelectedComponents(items);
      }
    } else if(result.destination.droppableId == 'components') {
      //add the component back into localComponents
      console.log("dropped in components")
      const component = selectedComponents.find((component) => component.key === result.draggableId);
      if (component) {
        setLocalComponents([...localComponets, component]);
        //remove component from selected components list
        const items = Array.from(selectedComponents);
        const [reorderedItem] = items.splice(result.source.index, 1);
        setSelectedComponents(items);

      }
    }


  }

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
          
          <Grid container >
            <Grid item lg={12} >
              <Typography variant="h5" align="left" gutterBottom>
                New Weekly Report
              </Typography>
              <Typography align="left" gutterBottom fontSize={16}>
                Select start Day eg, Monday - Sunday and the properties and components to include in the report.  The report will run each week at Midnght on the seleted Day to Run.
              </Typography>
              <Card>
                <MDBox p={1} px={2}>
                  <Grid container>
                    <Grid item lg={6} p={2}>
                      <TextField
                        fullWidth
                        label="Report Name"
                        name="description"
                        value={formValues.description}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item lg={3} p={2}>
                      {/* Select the day of week to start the report */}
                      <WeekSelect />
                      
                    </Grid>
                    <Grid item lg={3} p={2}>
                      <DaySelect/>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
          
          <Grid item lg={12} >
            <DragDropContext onDragEnd={handleOnDragEnd}>
            <Grid container>
              <Grid item lg={4} p={2}>
                <Card>
                  <MDBox p={1} px={2}>
                    <Typography variant="h6" align="left" gutterBottom>
                      Report Properties
                    </Typography>
                    <Divider/>
                    <FormGroup>
                    {properties.map((property) => (
                      <FormControlLabel
                       
                        key={property.key}
                        control={
                          <Checkbox
                            checked={selectedProperties.some((option) => option.key === property.key)}
                            onChange={handlePropertyChange}
                            value={property.key}
                          />
                        }
                        label={property.value}
                      />
                      ))}
                    </FormGroup>
                  </MDBox>
                </Card>
              </Grid>
           
              {/* <Grid item lg={4} p={2}>
                <Card>
                  <MDBox p={1} px={2}>
                    <Typography variant="h6" align="left" gutterBottom>
                      Components
                    </Typography>
                    <Divider/>
                    <FormGroup>
                      {components.map((component) => (
                        <FormControlLabel
                          draggable={true}
                          onDragStart={(e) => dragStart(e, component)}
                          onDragEnter={(e) => dragEnter(e, component)}
                          onDragEnd={(e) => drop(e, component)}
                          key={component.key}
                          control={
                            <Checkbox
                              checked={selectedComponents.some((option) => option.key === component.key)}
                              onChange={handleComponentChange}
                              value={component.key}
                            />
                          }
                          label={component.value}
                        />
                      ))}
                    </FormGroup>
                  </MDBox>
                </Card>
              </Grid> */}
              
              <Grid item lg={4} p={2}>
                <Card >
                  <MDBox p={1} px={2} height={600}>
                    <Typography variant="h6" align="left" gutterBottom id='selectedComponentsBox'>
                      Components
                    </Typography>
                    <Divider/>
                    
                      <Droppable droppableId="components">
                        {(provided) => (
                          <ul className="components" {...provided.droppableProps} ref={provided.innerRef}
                          style={{
                            listStyle: 'none',
                            paddingLeft: 0
                          }}>
                            {localComponets&&localComponets.map(({key, value}, index) => {
                              return (
                                <Draggable key={key} draggableId={key} index={index}>
                                  {(provided) => (
                                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                    >
                                      <h5 
                                        style={{
                                          listStyle: 'none',
                                          padding: 6,
                                          border: '1px solid #ccc',
                                          borderRadius: '4px',  
                                          marginTop: '4px',
                                          marginBottom: '4px',
                                          backgroundColor: 'ButtonFace',
                                      }}
                                      >
                                        { value }
                                      </h5>
                                    </li>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    
                    {/* <FormGroup>
                      

                    </FormGroup> */}
                    {/* <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="characters"></Droppable>
                      <List>
                        {selectedComponents.map((component) => (
                          <ListItem key={component.key}>
                            <ListItemText primary={component.value} />
                          </ListItem>
                        ))}
                      </List>
                      </Droppable>
                    </DragDropContext>                     */}
                  </MDBox>
                </Card>
              </Grid>
              <Grid item lg={4} p={2}>
                <Card >
                {/* <DragDropContext onDragEnd={handleOnDragEnd}> */}
                  <Droppable droppableId="selectedComponents">
                    {(provided) => (
                      <MDBox p={1} px={2} height={500}>
                        <Typography variant="h6" align="left" gutterBottom id='selectedComponentsBox'>
                          Selected Components
                        </Typography>
                        <Divider/>
                        <ul className="selectedComponents" {...provided.droppableProps} ref={provided.innerRef}
                        style={{
                          listStyle: 'none',
                          paddingLeft: 0,
                        }}>
                          {selectedComponents.map(({key, value}, index) => {
                            return (
                              <Draggable key={key} draggableId={key} index={index}>
                                {(provided) => (
                                  <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                  >
                                    <h5 
                                      style={{
                                      listStyle: 'none',
                                      padding: 6,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',  
                                      marginTop: '4px',
                                      marginBottom: '4px',
                                      backgroundColor: 'ButtonFace',
                                    }}
                                    >
                                      { value }
                                    </h5>
                                  </li>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                            
                          
                        {/* <FormGroup>
                          

                        </FormGroup> */}
                        {/* <DragDropContext onDragEnd={handleOnDragEnd}>
                          <Droppable droppableId="characters"></Droppable>
                          <List>
                            {selectedComponents.map((component) => (
                              <ListItem key={component.key}>
                                <ListItemText primary={component.value} />
                              </ListItem>
                            ))}
                          </List>
                          </Droppable>
                        </DragDropContext>                     */}
                      </MDBox>
                    )}
                  </Droppable>
                  {/* </DragDropContext> */}
                </Card>
              </Grid>
            </Grid>
            </DragDropContext>
          </Grid>
          <Grid item lg={12} py={2}>
            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary" style={{ color: 'white' }}>
                {buttonText}
              </Button>
            </Box>
          </Grid>
      </form>
    </Container>
  );
};

export default ReportForm;
