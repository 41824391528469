import { BarDatum, ResponsiveBar } from '@nivo/bar'
import React from 'react';
import { ResponsivePie, MayHaveLabel } from '@nivo/pie';
import Card from "@mui/material/Card";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

interface BarChartProps {
  channelData: Record<string, number>;
}

const BarChart: React.FC<BarChartProps> = ({ channelData }) => {

  const channelDataArray = Object.entries(channelData);
  channelDataArray.sort((a, b) => a[0].localeCompare(b[0]));
  const sortedChannelData = Object.fromEntries(channelDataArray);

  const data: BarDatum[] = Object.entries(sortedChannelData).map(([sourceName, badgeContent],index) => ({
    id: sourceName,
    label: sourceName.substring(0, 10),
    value: badgeContent
  }));

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6">Booking Channels</MDTypography>
      </MDBox>
      <div style={{ height: '400px' }}>

        <ResponsiveBar
            data={data}
            // keys={[
            //       'hot dog',
            //       'burger',
            //       'sandwich',
            //       'kebab',
            //       'fries',
            //       'donut'
            //   ]}
            // indexBy="country"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            layout="vertical"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'nivo' }}
            // defs={[
            //     {
            //         id: 'dots',
            //         type: 'patternDots',
            //         background: 'inherit',
            //         color: '#38bcb2',
            //         size: 4,
            //         padding: 1,
            //         stagger: true
            //     },
            //     {
            //         id: 'lines',
            //         type: 'patternLines',
            //         background: 'inherit',
            //         color: '#eed312',
            //         rotation: -45,
            //         lineWidth: 6,
            //         spacing: 10
            //     }
            // ]}
            // fill={[
            //     {
            //         match: {
            //             id: 'fries'
            //         },
            //         id: 'dots'
            //     },
            //     {
            //         match: {
            //             id: 'sandwich'
            //         },
            //         id: 'lines'
            //     }
            // ]}
            // borderColor={{
            //     from: 'color',
            //     modifiers: [
            //         [
            //             'darker',
            //             1.6
            //         ]
            //     ]
            // }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Booking Channel',
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Reservations',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            // legends={[
            //     {
            //         dataFrom: 'keys',
            //         anchor: 'bottom-right',
            //         direction: 'column',
            //         justify: false,
            //         translateX: 120,
            //         translateY: 0,
            //         itemsSpacing: 2,
            //         itemWidth: 100,
            //         itemHeight: 20,
            //         itemDirection: 'left-to-right',
            //         itemOpacity: 0.85,
            //         symbolSize: 20,
            //         effects: [
            //             {
            //                 on: 'hover',
            //                 style: {
            //                     itemOpacity: 1
            //                 }
            //             }
            //         ]
            //     }
            // ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
        />
      </div>
    </Card>
)}

export default BarChart;