import React, { useEffect, useState, useRef } from 'react';
import TextField, { FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextFieldVariants } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers';
import { Card, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, List, ListItem, ListItemText, MenuItem, Select } from '@mui/material';
import MDBox from 'components/MDBox';
import { getHeaders } from "auth/authService";
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MDButton from 'components/MDButton';
import { BorderAllRounded } from '@mui/icons-material';


const initialValues: {
  description: string;
  selectedComponents: { key: string; value: string }[];
  companyID: string;
} = {
  description: '',
  selectedComponents: [],
  companyID: '',
};

interface FormProps {
  properties: { key: string; value: string }[];
  components: { key: string; value: string }[];
  companyId: string
}



const ReportTemplateForm: React.FC<FormProps> = ({ properties, components, companyId }) =>{
  const navigate = useNavigate();
  const [localComponets, setLocalComponents] = useState<{ key: string; value: string }[]>(Array.from(components));

  
  useEffect(() => {
    setFormValues({
      ...formValues,
      companyID: companyId,
    });
    setLocalComponents(Array.from(components))
  },[components])
  
  const [formValues, setFormValues] = useState(initialValues);
  const [company_id, setCompanyID] = useState(companyId)
  const [buttonText, setButtonText] = useState("Save Report Template")

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const [selectedProperties, setSelectedProperties] = useState<{ key: string; value: string }[]>([]);

  const [selectedComponents, setSelectedComponents] = useState<{ key: string; value: string }[]>([]);

  const handleComponentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    const optionValue = event.target.value;
    setSelectedComponents((prevSelectedOptions) => {
      if (prevSelectedOptions.some((option) => option.key === optionValue)) {
        return prevSelectedOptions.filter((option) => option.key !== optionValue);
      } else {
        const component = components.find((component) => component.key === optionValue);
        if (component) {
          return [...prevSelectedOptions, component];
        } else {
          return prevSelectedOptions;
        }
      }
    });
    setFormValues({
      ...formValues,
      selectedComponents: components,
    });
  };

  const sleep = (ms: any) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
  });

  const submitForm = async (values: any) => {

    setButtonText("Saving...")
    //console.log("saving new report template:", values)

    //Save property
    const body = JSON.stringify(
      {
        "description": values.description,
        "companyId": values.companyID,
        "selectedComponents": selectedComponents,
        "userId": sessionStorage.getItem('user_id'),

      })
      
    let headers = await getHeaders();

    const response = await fetch(`${process.env.REACT_APP_API_URL}/reports/templates`, { 
      mode: 'cors',
      method: 'post',
      body: body,
      headers: headers,
    })

    //console.log("response:", response)
    await sleep(2000);
    navigate("/reports/templates")

  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitForm(formValues);
  };



  function handleOnDragEnd(result: { destination: { index: number; droppableId: string}; source: { index: number; }; draggableId: string;}) {
    
    //console.log("handleOnDragEnd result:", result)
    if (!result.destination) return;

    if(result.destination.droppableId == 'selectedComponents') {
      //console.log("dropped in selected components")
          // check if the dropped item is in the selected components list and if not then add it to the list
      if(!selectedComponents.some((option) => option.key === result.draggableId)) {
        const component = localComponets.find((component) => component.key === result.draggableId);
        if (component) {
          setSelectedComponents([...selectedComponents, component]);
        }
        //remove component from components list
        const items = Array.from(localComponets);
        const [reorderedItem] = items.splice(result.source.index, 1);
        setLocalComponents(items);

      }else{
        //reorder if required
        //console.log("reorder selected components")
        const items = Array.from(selectedComponents);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        setSelectedComponents(items);
      }
    } else if(result.destination.droppableId == 'components') {
      //add the component back into localComponents
      //console.log("dropped in components")
      const component = selectedComponents.find((component) => component.key === result.draggableId);
      if (component) {
        setLocalComponents([...localComponets, component]);
        //remove component from selected components list
        const items = Array.from(selectedComponents);
        const [reorderedItem] = items.splice(result.source.index, 1);
        setSelectedComponents(items);

      }
    }
  }

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>

      <Grid container >
            <Grid item lg={12} >
            <Typography variant="h5" align="left" gutterBottom>
                New Report Template
              </Typography>
              <Typography align="left" gutterBottom fontSize={16}>
                Select components and rearrange the order for them to appear.
              </Typography>
              <Card>
                <MDBox p={1} px={2}>
                  <Grid container>
                    <Grid item lg={6} p={2}>
                      <TextField
                        fullWidth
                        label="Template Name"
                        name="description"
                        value={formValues.description}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
          
          <Grid item lg={12} >
            <DragDropContext onDragEnd={handleOnDragEnd}>
            <Grid container>
              <Grid item lg={4} p={2}>
                <Card >
                  <MDBox p={1} px={2} height={600}>
                    <Typography variant="h6" align="left" gutterBottom id='selectedComponentsBox'>
                      Components
                    </Typography>
                    <Divider/>
                    
                      <Droppable droppableId="components">
                        {(provided) => (
                          <ul className="components" {...provided.droppableProps} ref={provided.innerRef}
                          style={{
                            listStyle: 'none',
                            paddingLeft: 0
                          }}>
                            {localComponets&&localComponets.map(({key, value}, index) => {
                              return (
                                <Draggable key={key} draggableId={key} index={index}>
                                  {(provided) => (
                                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                    >
                                      <h5 
                                        style={{
                                          listStyle: 'none',
                                          padding: 6,
                                          border: '1px solid #ccc',
                                          borderRadius: '4px',  
                                          marginTop: '4px',
                                          marginBottom: '4px',
                                          backgroundColor: 'ButtonFace',
                                      }}
                                      >
                                        { value }
                                      </h5>
                                    </li>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    
                  </MDBox>
                </Card>
              </Grid>
              <Grid item lg={4} p={2}>
                <Card >
                  <Droppable droppableId="selectedComponents">
                    {(provided) => (
                      <MDBox p={1} px={2} height={600}>
                        <Typography variant="h6" align="left" gutterBottom id='selectedComponentsBox'>
                          Selected Components
                        </Typography>
                        <Divider/>
                        <ul className="selectedComponents" {...provided.droppableProps} ref={provided.innerRef}
                        style={{
                          listStyle: 'none',
                          paddingLeft: 0,
                        }}>
                          {selectedComponents.map(({key, value}, index) => {
                            return (
                              <Draggable key={key} draggableId={key} index={index}>
                                {(provided) => (
                                  <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                  >
                                    <h5 
                                      style={{
                                      listStyle: 'none',
                                      padding: 6,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',  
                                      marginTop: '4px',
                                      marginBottom: '4px',
                                      backgroundColor: 'ButtonFace',
                                    }}
                                    >
                                      { value }
                                    </h5>
                                  </li>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                      </MDBox>
                    )}
                  </Droppable>
                </Card>
              </Grid>
            </Grid>
            </DragDropContext>
          </Grid>
          <Grid item lg={12} py={2}>
            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary" style={{ color: 'white' }}>
                {buttonText}
              </Button>
            </Box>
          </Grid>
      </form>
    </Container>
  );
};

export default ReportTemplateForm;
