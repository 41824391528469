/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "layouts/pages/reports/new-report/schemas/form";

const {
  formField: { Description, StartDate, EndDate, PropertyID },
} = checkout;

const validations = [
  Yup.object().shape({
    [Description.name]: Yup.string().required(Description.errorMsg),
    [StartDate.name]: Yup.string().required(StartDate.errorMsg),
    [EndDate.name]: Yup.string().required(EndDate.errorMsg),
    [PropertyID.name]: Yup.string().required(PropertyID.errorMsg)
  }),
  
];

export default validations;
