/**
PEAK REVEUNE
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { ReactHTMLElement, useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import Link from "@mui/material/Link";
import Button from "assets/theme/components/button";

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Switch } from '@mui/material';
import { bool } from "aws-sdk/clients/signer";
import MyobImage from 'assets/images/myob.com.svg';
import RMSImage from 'assets/images/rms-logo.svg';
import { getHeaders } from "auth/authService";
import { AppContext, UserData } from 'lib/appContext';

const ReportsTable: React.FC = () => {  

  const [tableloaded, settableLoaded] = useState<boolean>(false)
  const [dataTableData, setDataTableData] = useState<any>(null)
  const [companyID, setCompanyID] = useState<any>(null)
  const [error, setError] = useState<string>(null)
  const [resetData, setResetData] = useState<any>(null)

  useEffect(() => {
    const setUp = async () => {
      const cid = sessionStorage.getItem("company_id")
      setCompanyID(cid)
    }
    setUp()
  },[])

  // useEffect(() => {
  //   console.log("dataTableData updated:", dataTableData)
  // }
  // ,[dataTableData])

  async function handleDelete(rid:string){
    // console.log("delete: ", rid)
    // console.log("dataTableData:", dataTableData)
    const cid = sessionStorage.getItem("company_id")
    try {
      const headers = await getHeaders()
      const link = `${process.env.REACT_APP_API_URL}/reports/${rid}?cid=${cid}`
      // console.log("link:", link)
      const response = await fetch(link, {
        mode: 'cors',
        method: 'DELETE', 
        headers: headers,
      })
      //check if status is 200 and remove report from dataTableData
      if(response.status === 200){
        setResetData(new Date())
      }
      
      //console.log("report deleted:", rid)
      
    } catch (error) {
      console.error("Error:", error)
    }
  }

  useEffect(() => {

    const loadReports = async (cid:string) => {
      try {
        const headers = await getHeaders()
        const response = await fetch(`${process.env.REACT_APP_API_URL}/reports/${cid}`, {
          mode: 'cors',
          method: 'GET', 
          headers: headers,
        })
        const data = await response.json();
        //console.log("reports:", data)
        const uid = sessionStorage.getItem("user_id")
        //console.log("uid:", uid)
        if(!data.errors){
          //Process Return Data for all reports
          const td = data
            .filter((item: any) => item.UserID === uid)
            .map((item: any) => ({
              UID: item.UID,
              Description: getLink(item, item.Description, cid),
              StartDate: formatDates(item.StartDate, item.EndDate),
              EndDate: new Date(item.CreatedDate).toLocaleDateString(),
              Del: <MDButton               
              size="large"
              iconOnly={true}
              onClick={()=>{handleDelete(item.UID)}}>
              <DeleteForeverIcon/></MDButton>,
              PropertyID: getPropertyNames(item.SelectedProperties),
            }
          ));
          const table = {
            columns: [
              { Header: "Description", accessor: "Description" },          
              { Header: "Reporting Period", accessor: "StartDate" },
              // { Header: "Created Date", accessor: "EndDate", width:6 },
              { Header: "", accessor: "Del", width:4},
              { Header: "Property", accessor: "PropertyID", width:10 },
            ],
            rows: td
          }

          setDataTableData(table)
          settableLoaded(true)
          //console.log("dataTableData set:", dataTableData)

        }else{
          setError("There was an issue retrieving reports")
          throw new Error(data.errors);
        }
      } catch (error) {
        console.error("Error:", error)
      }
    }

    if(companyID!==null){
      loadReports(companyID)
    }

  }, [companyID, resetData])
  
  function formatDates(startDate:string, endDate:string): string{
    const sDate = new Date(startDate)
    const eDate = new Date(endDate)
    return `${sDate.toLocaleDateString('en-AU')} - ${eDate.toLocaleDateString('en-AU')}`
  }

  function getPropertyNames(properties:string): string{
    const props = JSON.parse(properties)
    const propertyNames = props.map((item:any)=> <MDButton size="small" variant="outlined" color="success" disableElevation>{item.value.substring(0, 3)}</MDButton>)
    return propertyNames//.join(", ")
  }

  function getLink(item:any, linkText:string, cid:string): JSX.Element{
      return <Link href={`/reports/view?rid=${item.UID}&cid=${cid}`} color="Grey">{linkText}</Link>
  }

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>

        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Reports 
            </MDTypography>
            <MDTypography variant="button" color="text">
              All Reports for your Company
            </MDTypography>
          </MDBox>
          {tableloaded &&
            <DataTable table={dataTableData} isSorted={false} />
          }
          <MDBox p={2}>
            <MDButton
              color="dark" 
              size="medium"
              href={`/reports/new?companyId=${sessionStorage.getItem("company_id")}`}
            >
              Create a new Report
            </MDButton>
          </MDBox>
          {/* <MDBox p={2}>
            <MDButton
              color="info" 
              size="medium"
              href={`/reports/sched/new?companyId=${sessionStorage.getItem("company_id")}`}
            >
              Create a new Scheduled Report
            </MDButton>
          </MDBox> */}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ReportsTable;

