

import { ReactNode } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";

import { Icon } from "@mui/material";


// Declaring prop types for DefaultStatisticsCard
interface Props {
  title: string;
  count: string | number;
  variance?: {
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "white";
    value: string | number;
  };
  target: string | number;
  previous?: {
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "white";
    value: string | number;
  };
  icon: ReactNode;
  [key: string]: any;
}

function StandardStatisticsCard({ title, count, variance, color, target, previous, icon }: Props): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;



  return (
    <Card>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={10}>
            <Grid container>
              <Grid item lg={11}>
                <MDBox textAlign="left" lineHeight={1.25} alignItems="left" >
                  <MDTypography variant="button" fontWeight="bold" color="text">
                    <h3>{title}</h3>
                  </MDTypography>

                </MDBox>
              </Grid>
              <Grid item lg={1} alignContent="right" >
                  <MDBox
                    variant="gradient"
                    bgColor={color}
                    color={color === "light" ? "dark" : "white"}
                    coloredShadow={color}
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    mt={-3}
                    border="black"
                    
                  > 
                  <Icon fontSize="large" color="inherit">
                    {icon}
                  </Icon>
                </MDBox>
              </Grid>

            </Grid>

            <MDBox lineHeight={1} width="100%">
              <MDTypography variant="h5" fontWeight="bold" color={color}>
                {count}
              </MDTypography>
              <MDTypography variant="button" fontWeight="bold" color={variance.color}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="secondary"
                >
                  Comparision to target: 
                </MDTypography>
                &nbsp;{target}
              </MDTypography>
            </MDBox>
            <MDBox lineHeight={1} width="100%">
              <MDTypography variant="button" fontWeight="bold" color={variance.color}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="secondary"
                >
                  Comparison to previous period: 
                </MDTypography>
                &nbsp;{variance.value}
              </MDTypography>
            </MDBox>
            <MDBox lineHeight={1} width="100%">
              <MDTypography variant="button" fontWeight="bold" color={previous.color}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="secondary"
                >
                  Same period last year:
                </MDTypography>
                &nbsp;{previous.value}
              </MDTypography>
            </MDBox>
          </Grid>

        </Grid>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultStatisticsCard
StandardStatisticsCard.defaultProps = {
  percentage: {
    color: "success",
    value: "",
    label: "",
  },
  icon: null,
};

export default StandardStatisticsCard;
