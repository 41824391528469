/**
PEAK REVEUNE
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "./components/DataTable"

// Data
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import Link from "@mui/material/Link";

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getHeaders } from "auth/authService";

const ReportsTable: React.FC = () => {  

  const [tableloaded, settableLoaded] = useState<boolean>(false)
  const [dataTableData, setDataTableData] = useState<any>(null)
  const [companyID, setCompanyID] = useState<any>(null)
  const [error, setError] = useState<string>(null)
  const [resetData, setResetData] = useState<any>(null)

  useEffect(() => {
    const setUp = async () => {
      console.log("company_id:",sessionStorage.getItem("company_id") )
      const cid = sessionStorage.getItem("company_id")
      setCompanyID(cid)
    }
    setUp()
  },[])

  useEffect(() => {
    console.log("dataTableData updated:", dataTableData)
  }
  ,[dataTableData])

  async function handleDelete(tid:string){
    // console.log("delete: ", tid)
    // console.log("dataTableData:", dataTableData)
    const cid = sessionStorage.getItem("company_id")
    try {
      const headers = await getHeaders()
      const link = `${process.env.REACT_APP_API_URL}/reports/templates/${tid}?cid=${cid}`
      // console.log("link:", link)
      const response = await fetch(link, {
        mode: 'cors',
        method: 'DELETE', 
        headers: headers,
      })
      //check if status is 200 and remove report from dataTableData
      if(response.status === 200){
        setResetData(new Date())
      }
      
      //console.log("report deleted:", tid)
      
    } catch (error) {
      console.error("Error:", error)
    }
  }

  useEffect(() => {

    const loadReportTemplates = async (cid:string) => {
      try {
        const headers = await getHeaders()
        const response = await fetch(`${process.env.REACT_APP_API_URL}/reports/templates/${cid}`, {
          mode: 'cors',
          method: 'GET', 
          headers: headers,
        })
        const data = await response.json();
        console.log("reports templates:", data)
        const uid = sessionStorage.getItem("user_id")
        console.log("uid:", uid)
        if(!data.errors){
          //Process Return Data for all reports
          const td = data
            //.filter((item: any) => item.UserID === uid)
            .map((item: any) => ({
              UID: item.UID,
              Description: getLink(item, item.description, cid),
              Components: getComponentNames(item.selectedComponents),
              Del: <MDButton               
              size="large"
              iconOnly={true}
              onClick={()=>{handleDelete(item.UID)}}>
              <DeleteForeverIcon/></MDButton>,
            }
          ));
          const table = {
            columns: [
              { Header: "Description", accessor: "Description" },          
              { Header: "Components", accessor: "Components", width:10 },
              { Header: "Delete", accessor: "Del", width:4},
            ],
            rows: td
          }

          setDataTableData(table)
          settableLoaded(true)
          //console.log("dataTableData set:", dataTableData)

        }else{
          setError("There was an issue retrieving reports")
          throw new Error(data.errors);
        }
      } catch (error) {
        console.error("Error:", error)
      }
    }

    if(companyID!==null){
      loadReportTemplates(companyID)
    }

  }, [companyID, resetData])

  function getComponentNames(components: string): JSX.Element[] {
    const props = JSON.parse(components);
    const componentNames = props.map((item: any) => 
      <li key={item.value}>
        {item.value}
      </li>
    );
    console.log("componentNames:", componentNames);
    return componentNames;
  }

  function getLink(item:any, linkText:string, cid:string): JSX.Element{
      return <Link href={`/reports/templates/view?tid=${item.UID}&cid=${cid}`} color="Grey">{linkText}</Link>
  }

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>

        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Report Templates 
            </MDTypography>
            <MDTypography variant="button" color="text">
              All Report Templates for your Company
            </MDTypography>
          </MDBox>
          {tableloaded &&
            <DataTable table={dataTableData} isSorted={false} />
          }
          <MDBox p={2}>
            <MDButton
              color="dark" 
              size="medium"
              href={`/reports/templates/new?companyId=${sessionStorage.getItem("company_id")}`}
            >
              Create a new Report Template
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ReportsTable;

