/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-report-form",
  formField: {
    Description: {
      name: "description",
      label: "Description",
      type: "text",
      errorMsg: "Description is required.",
    },

    StartDate: {
      name: "StartDate",
      label: "Report Start Date",
      type: "text",
      errorMsg: "Start Date is required.",
    },
    EndDate: {
      name: "EndDate",
      label: "Report End Date",
      type: "text",
      errorMsg: "End Date is required.",
    },
    PropertyID: {
      name: "PropertyID",
      label: "RMS property ID",
      type: "text",
      errorMsg: "Property ID is required.",
    },
  },
};

export default form;
