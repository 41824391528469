
export type DashBoard={
    Name: string,
    ComponentGroups: DashboardComponentGroup[]
  }
  
export type DashboardComponentGroup={
    Name: string,
    Components: DashBoardComponent[]
  }
  
export type DashBoardComponent={
    Name: string,
    AccountID: string,
    Prefix: string,
    Target: number,
    Icon: string,
    Source: string,
    TargetType: string,
    Label:string,
    Size: number
  }

  
export const getPropertyDetails = async (propertyID:string, companyID:string, hdrs: HeadersInit) => {
  try {
      const queryParams = `?propertyId=${propertyID}&companyId=${companyID}`
      const response: any  = await fetch(`${process.env.REACT_APP_API_URL}/property${queryParams}`, { 
      mode: 'cors',
      method: 'get',
      headers: hdrs,
      })
      const data = await response.json();
      return data

  } catch (error) {
      console.log("Error retreiving Property", error)
  }
}

// Method to pull Reservation Data from RMS
export const getReservations = async (startDate: string, endDate: string, hdrs: HeadersInit): Promise<any> => {

  const body = JSON.stringify({
      "fromDate": startDate,
      "toDate": endDate,
      "propertyID": 1,
  });
  
  try {
    const fetchedPost = await fetch(`${process.env.REACT_APP_API_URL}/rms/reservations`, {
      mode: 'cors',
      method: 'post',
      body: body,
      headers: hdrs,
    });
    
    const data = await fetchedPost.json();
    console.log("Reservations ", data)
    return data

  } catch (error) {
    console.error("Error:", error);
    return 0;
  }
};
// export function setSalesDates(priorDays: number, end?: Date): void{

//   const endDate = end ? new Date(end) : new Date();
//   const localEndDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000));
  
//   const priorDate = new Date(new Date().setDate(endDate.getDate() - priorDays));
//   const localStartDate = new Date(priorDate.getTime() - (priorDate.getTimezoneOffset() * 60000));
  
//   setStartDate(localStartDate.toISOString().split('T')[0]);
//   setEndDate(localEndDate.toISOString().split('T')[0]);
// }
// export function setPreviousWeekDates() {
//   const today = new Date();
//   const previousSunday = new Date(today);
//   previousSunday.setDate(today.getDate() - today.getDay());
//   setSalesDates(7, previousSunday)
// }

// export function setLastMonthDates() {
//   const today = new Date();
//   const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

//   const lastDayOfLastMonth = new Date(firstDayOfCurrentMonth);
//   lastDayOfLastMonth.setDate(firstDayOfCurrentMonth.getDate() - 1);

//   const firstDayOfLastMonth = new Date(lastDayOfLastMonth);
//   firstDayOfLastMonth.setDate(1);

//   console.log('Start Date:', firstDayOfLastMonth.toISOString());
//   console.log('End Date:', lastDayOfLastMonth.toISOString());

//   setStartDate(firstDayOfLastMonth.toISOString())
//   setEndDate(lastDayOfLastMonth.toISOString())
// }

export function convertDate(inputDate: Date): string | null {
  // Check if the input date matches the "DD/MM/YYYY" format
  const lDate = inputDate.toLocaleDateString()
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  const match = lDate.match(dateRegex);

  if (match) {
    const day = match[1];
    const month = match[2];
    const year = match[3];

    const newDate = `${year}-${month}-${day}`;
    return newDate
  }

  return null;
}

export const getEmberUrl = async (accountId: string, dashboardId: string, openIdToken: string, userArn: string, roleArn: string, sessionName: string, hdrs: HeadersInit) => {

  const body = {
    input: {
      accountId: accountId,
      dashboardId: dashboardId,
      openIdToken: openIdToken,
      userArn: userArn,
      roleArn: roleArn,
      sessionName: sessionName,
      getEmbedUrlCallback: (result: any) => {},
      errorCallback: (error: any) => {}
    
    }
  }
  try {
    const response: any  = await fetch(`${process.env.REACT_APP_API_URL}/analytics/getEmbedUrl`, { 
      mode: 'cors',
      method: 'post',
      body: JSON.stringify(body),
      headers: hdrs,
    });
    const data = await response.json();
    console.log("embed url response ", data);
    return data;

  } catch (error) {
    console.log("Error retreiving Embed URL", error)
  }
}