import { Divider, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import MDBox from "components/MDBox"

function Services(data: any[], name:string, current: boolean) {
  var serviceData
  if(!current){
    serviceData = data.find((item: any) => item.name === name)
  }else{
    serviceData = data
  }
  //const serviceData = data.find((item: any) => item.name === name)
  if (serviceData && serviceData.services && serviceData.services.length > 0) {
    
     return (
      serviceData.services.map((service: any) => (
        <TableRow>
        <TableCell style={{ width: '33%' }}>{service.name}</TableCell>
        <TableCell style={{ width: '33%' }}>{service.count}</TableCell>
        <TableCell style={{ width: '33%' }}>{service.timeAllocated}</TableCell>
        <TableCell style={{ width: '33%' }}>{(service.timeAllocated/service.count).toFixed(0)}</TableCell>
      </TableRow>
      )
     ))
  }else{
    return (
      <TableRow>
        <TableCell colSpan={3}>No services allocated</TableCell>
      </TableRow>
    )
  }
}

function timeAllocated(data: any[], name:string, current: boolean) {
  var serviceData
  if(!current){
    serviceData = data.find((item: any) => item.name === name)
  }else{
    serviceData = data
  }
  if (serviceData && serviceData.services && serviceData.services.length > 0) {
    const totalMinutes = serviceData.services.reduce((total: number, service: any) => total + service.timeAllocated, 0);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const timeString = `${hours}H:${minutes}M`;
     return (
      `${timeString}`
    )
  }else{
    return (
      0
    )
  }                        
}

function serviceTotals(data: any[], name:string, current: boolean) {
  var serviceData
  if(!current){
    serviceData = data.find((item: any) => item.name === name)
  }else{
    serviceData = data
  }
  if (serviceData && serviceData.services && serviceData.services.length > 0) {
     return (
      serviceData.services.reduce((total:number, service:any) => total + service.count, 0)
    )
  }else{
    return (
      0
    )
  }                        
}
function timeSpent(data: any[], name:string, current: boolean) {
  var serviceData
  if(!current){
    serviceData = data.find((item: any) => item.name === name)
  }else{
    serviceData = data
  }
  if (serviceData && serviceData.services && serviceData.services.length > 0) {
    const totalMinutes = serviceData.timeSpent.toFixed(0)
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const timeString = `${hours}H:${minutes}M`;
     return (
      `${timeString}`         
    )
  }else{
    return (
      0
    )
  } 
}

function timeVariance(data: any[], name:string, current: boolean) {
  var serviceData
  if(!current){
    serviceData = data.find((item: any) => item.name === name)
  }else{
    serviceData = data
  }
  if (serviceData && serviceData.services && serviceData.services.length > 0) {
    const totalMinutes = serviceData.timeSpent.toFixed(0)-serviceData.services.reduce((total:number, service:any) => total + service.timeAllocated, 0)
    const posNeg = totalMinutes > 0 ? "+" : "-";
    const hours = Math.floor(Math.abs(totalMinutes) / 60);
    const minutes = Math.abs(totalMinutes) % 60;
    const timeString = `${hours}H:${minutes}M`;
     return (
      <Typography variant="body2" color={posNeg === "+" ? "error" : "success"}>
      {`${posNeg}${timeString}`}
      </Typography>
    )
  }else{
    return (
      0
    )
  }
}

function convertMinutesToHours(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = (minutes % 60).toFixed(0);
  const timeString = `${hours}H:${minutesLeft}M`;
  return timeString;
}

function timeToClean(data: any[], name:string, current: boolean) {
  var serviceData
  if(!current){
    serviceData = data.find((item: any) => item.name === name)
  }else{
    serviceData = data
  }
  if (!serviceData || serviceData.length === 0) {
    return <div></div>;
  }

  console.log("serviceDatax:", serviceData)
  const totalMinutes = serviceData.timeSpent
  //console.log("totalMinutes:",totalMinutes)
  // const hours = Math.floor(totalMinutes / 60);

  //find the ratio of time allocated per service type
  type ratios = {
    name: string;
    ratio: number;
  };
  type serviceAverages = {
    name: string;
    average: number;
  };
  const serviceAverages:serviceAverages[] = []
  serviceData.services.map((service: any) => (
    serviceAverages.push({name: service.name, average: service.timeAllocated/service.count})
  ))
  console.log("serviceAveragesx:",serviceAverages)

  const serviceRatios:ratios[] = []
  //const totalAverages = serviceAverages.reduce((total: number, service: any) => total + service.average, 0);
  
  //use serviceAverages and totalMinutes to calculate the ratio of time allocated per service type
  // serviceAverages.map((service: any) => (
    //serviceRatios.push({name: service.name, ratio: service.average/totalAverages})
  const totalMinutesAllocated = serviceData.services.reduce((total: number, service: any) => total + service.timeAllocated, 0);
  console.log("totalMinutesAllocated:",totalMinutesAllocated)
  serviceData.services.map((service: any) => (
    serviceRatios.push({name: service.name, ratio: service.timeAllocated/totalMinutesAllocated})
  ))

  console.log("serviceRatiosx:",serviceRatios)

  return (
  serviceData.services.map((service: any) => (
    <TableRow>
      <TableCell style={{ width: '33%' }}>{service.name}</TableCell>
      <TableCell style={{ width: '33%' }}>{
        ((totalMinutes*serviceRatios.find((item: any) => item.name === service.name).ratio)/service.count).toFixed(0)
      }
      </TableCell>
      <TableCell style={{ width: '33%' }}>{
        convertMinutesToHours((totalMinutes*serviceRatios.find((item: any) => item.name === service.name).ratio))
      }</TableCell>
    </TableRow>
  )
  ))
}

export function resourcePerformance(data: any[]) {
  if (!data || data.length === 0) {
    return <div>No data</div>;
  }
  return data.map((property: any) => (
    <Grid item lg={12}>
      <MDBox p={2} py={3}>
        <Grid container>
          <Grid item lg={12}>
            {property.ID}
            {property.CurrentTotal.map((resource: any) => (
                
                <Grid container>
                  <Grid item lg={12} py={2}>
                    <Divider />
                    <Typography variant={"h6"}>Name: {resource.name}</Typography>
                  </Grid>
                  <Grid item lg={6} style={{ border: '1px solid black' }} p={2}>
                    <Typography variant={"h6"}>Current Period</Typography>
                    <Grid container >
                    <Table >
                      <TableBody>
                      <TableRow style={{ width: '100%'}}>
                          <TableCell style={{ width: '33%'  }}><Typography variant={"h6"}>Service Type</Typography></TableCell>
                          <TableCell style={{ width: '33%'}}><Typography variant={"h6"}>Count</Typography></TableCell>
                          <TableCell style={{ width: '33%'}}><Typography variant={"h6"}>Time Allocated</Typography></TableCell>
                          <TableCell style={{ width: '33%'}}><Typography variant={"h6"}>Avg</Typography></TableCell>
                        </TableRow>
                        {Services(resource, "", true)}
                        <TableRow style={{ backgroundColor: 'lightgrey' }}>
                            <TableCell>Total</TableCell>
                            <TableCell>{serviceTotals(resource, resource.name, true)}</TableCell>
                            <TableCell>
                            {timeAllocated(resource, "", true)}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow >
                            <TableCell>Time Taken</TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                            {timeSpent(resource, "", true)}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow >
                            <TableCell>Variance</TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                            {timeVariance(resource, "", true)}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow style={{ width: '100%'}}>
                            <TableCell style={{ width: '33%'  }}><Typography variant={"h6"}>Service Type</Typography></TableCell>
                            <TableCell style={{ width: '33%'}}><Typography variant={"h6"}>Avg Min</Typography></TableCell>
                            <TableCell style={{ width: '33%'}}><Typography variant={"h6"}>Total</Typography></TableCell>
                          </TableRow>
                            {timeToClean(resource, "", true)}
                      </TableBody>
                    </Table>
                  </Grid>
                  </Grid>
                  <Grid item lg={6}  style={{ border: '1px solid black' }} p={2}>
                    <Typography variant={"h6"}>Previous Period</Typography>
                    <Grid container>
                    <Table style={{border: 1}}>
                      <TableBody>
                      <TableRow style={{ width: '100%'}}>
                      <TableCell style={{ width: '33%'  }}><Typography variant={"h6"}>Service Type</Typography></TableCell>
                          <TableCell style={{ width: '33%'}}><Typography variant={"h6"}>Count</Typography></TableCell>
                          <TableCell style={{ width: '33%'}}><Typography variant={"h6"}>Time Allocated</Typography></TableCell>
                          <TableCell style={{ width: '33%'}}><Typography variant={"h6"}>Avg</Typography></TableCell>
                        </TableRow>
                        {Services(property.PreviousTotal, resource.name, false)}
                        <TableRow style={{ backgroundColor: 'lightgrey' }}>
                            <TableCell>Total</TableCell>
                            <TableCell>{serviceTotals(property.PreviousTotal, resource.name, false)}</TableCell>
                            <TableCell>
                              {timeAllocated(property.PreviousTotal, resource.name, false)}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow >
                            <TableCell>Time Taken</TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                            {timeSpent(property.PreviousTotal, resource.name, false)}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow >
                            <TableCell>Variance</TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                            {timeVariance(property.PreviousTotal, resource.name, false)}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow style={{ width: '100%'}}>
                            <TableCell style={{ width: '33%'  }}><Typography variant={"h6"}>Service Type</Typography></TableCell>
                            <TableCell style={{ width: '33%'}}><Typography variant={"h6"}>Avg Min</Typography></TableCell>
                            <TableCell style={{ width: '33%'}}><Typography variant={"h6"}>Total</Typography></TableCell>
                          </TableRow>
                            {timeToClean(property.PreviousTotal, resource.name, false)}
                      </TableBody>
                    </Table>
                  </Grid>
                  </Grid>
              </Grid> 
              
            ))}
   
          </Grid>       
        </Grid>
      </MDBox>
    </Grid>
  ));
}