

import { gl } from "chroma-js";
//import { AppContext, UserData } from "lib/appContext";
import { getHeaders } from "auth/authService";

//Retrieve Property Details from API


// types
interface DataType {
  labels: string[];
  datasets: {
    label: string;
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
    data: number[];
  }[];
}

//const [monthlyReturnData,setMonthlyReturnData] = useState(null)



//retrieve 12 months of transaction data from DynamoDB
const myobJournalTransData = async (propertyID: string, endDate:string, glCode:string, headers: HeadersInit) => {
  const lastDate = new Date(endDate);
  const startDate = new Date(new Date().setDate(lastDate.getDate() - 365)).toISOString();

  const body = JSON.stringify(
    {
      "months": 12,
      "toDate": endDate,
      "clientID": propertyID, //"7158f2a1-7f65-4759-ac71-c159c05441d9", //TODO: remove hard coded ID: This is the ClientID of the MYOB Integration
      "isCredit": "true",
      "accountId": glCode
    })

    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}/myob/getMonthlyJournalTransactions`, { 
        mode: 'cors',
        method: 'post',
        body: body,
        headers: headers,
      })
      const data = await response.json();
      //console.log("monthly sums data: ", data)
      return data
    } catch (error) {
      console.log("Error myobJournalTransData: ", error)
      return null
    }
}

const lineChartData = async (propertyID:string, endDate:string, glCode:string, headers: HeadersInit) => {

  const monthlySums:any = {};
  
  const data = await myobJournalTransData(propertyID, endDate,glCode, headers)

  const d: DataType = ({
    labels: Object.keys(data),
    datasets: [
      {
        label: "Received",
        color: "info",
        data: Object.values(data),
      },
    ],
  })

  // console.log("D: ", d)
  return d 

}

export default lineChartData;
