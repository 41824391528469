import { Autocomplete, Card, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import StandardStatisticsCard from "examples/Cards/StatisticsCards/StandardStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import * as MuiIcons from '@mui/icons-material';
import { Key, useEffect, useState } from "react";
import MDInput from "components/MDInput";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import FormField from "layouts/pages/account/components/FormField";
import {getHeaders} from "../../../../auth/authService";
import { useSearchParams, Link, useParams } from "react-router-dom";
import { 
    DashBoard, 
    DashboardComponentGroup, 
    DashBoardComponent, 
    getPropertyDetails, 
    getReservations, 
    convertDate
} from "lib/dashboard";
import { CostData } from "types/costData";
import hash from 'object-hash';
import MDButton from "components/MDButton";
import { useCache } from 'lib/CacheContext'; 
import LoadingSpinner from "components/Spinner";
import { DatePicker } from '@mui/x-date-pickers';
var moment = require('moment');
import dayjs from 'dayjs';

dayjs.locale('en-au')

interface DataPageProps {
    title: string
  }

  type DashboardComponentProps = {
    component: DashBoardComponent;
    glTotals: any;
  };

  const iconLookup: { [key: string]: React.ElementType } = {
    People: MuiIcons.People,
    Restaurant: MuiIcons.Restaurant,
    ViewDay: MuiIcons.ViewDay,
    BedroomParent: MuiIcons.BedroomParent,
    Paid: MuiIcons.Paid,
    CleaningServices: MuiIcons.CleaningServices
  };



  type DashboardComponentGroupProps = {
    group: DashboardComponentGroup;
    glTotals: any
  };


  //var dashboardCache = new Array()
  

function DataPage(): JSX.Element {
    const cache = useCache();
    const dollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

    const percentage = new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    function formatValue(prefix: string, value: number): string  {
        switch (prefix) {
            case "$":
                return dollar.format(value)
                break;
            case "%":
                return percentage.format(value)
                break;      
            default:
                return value.toString()
                break;
        }
    }

    function getCacheKey(did:string, fdate:Date,tdate:Date): string {
        console.log("cache date: ", fdate)
        if(tdate){
            return `${did}:${fdate.toISOString().split('T')[0]}:${tdate.toISOString().split('T')[0]}`
        }else{
            return ""
        }
        
    }

    const DashboardComponentItem: React.FC<DashboardComponentProps> = ({ component }) => {
        const IconComponent = iconLookup[component.Icon];
        const itemIndex = dashboardData.findIndex((item: { accountID: string; }) => item.accountID === component.AccountID);
        
        return (
            <Grid item lg={4}>
                <StandardStatisticsCard
                    color="info"
                    title={component.Name}
                    count={formatValue(component.Prefix, dashboardData[itemIndex].currentTotal)}
                    variance={{
                        color: `${dashboardData[itemIndex].variance>0?"success":"error"}`,
                        //value: `${formatValue("%",dashboardData[itemIndex].variance)}`
                        value: `${formatValue("$",dashboardData[itemIndex].previousTotal)}`
                    }}
                    target={formatValue(component.Prefix, dashboardData[itemIndex].target)}
                    previous={{
                        color: `${dashboardData[itemIndex].lastYear<dashboardData[itemIndex].currentTotal?"success":"error"}`,
                        value: `${formatValue(component.Prefix, dashboardData[itemIndex].lastYear)}`
                    }}
                    icon={IconComponent && <IconComponent />}
                />
            </Grid>
        );
      };

      const DashboardComponentGrp: React.FC<DashboardComponentGroupProps> = ({ group, glTotals }) => {
        return (
            <Grid item lg={12}>
                <MDTypography py={2}>
                    <h3>
                        <Link to={`/dashboard/accomodation/${propertyId}/${companyId}/${convertDate(startDate)}/${convertDate(endDate)}`} color="Grey">{group.Name}</Link>
                    </h3>
                </MDTypography>
                <Grid container spacing={3} alignItems="center">
                    {/* Loop through at this level for each component */}
                    {group.Components.map((component: DashBoardComponent, index: Key) => (
                        <DashboardComponentItem key={index} component={component} glTotals={glTotals}/>
                    ))}
    
                </Grid>
            </Grid>
        );
      };

    const initCostData: CostData = {
        "currentTotal":0,
        "previousTotal":0,
        "variance":0
      }

    type ComponentData = {
        "Key": string,
        "Values": CostData
    }
 
    const [startDate , setStartDate] = useState<Date>()
    const [endDate , setEndDate] = useState<Date>()
    const [startDisplayDate , setStartDisplayDate] = useState<any>(null)
    const [endDisplayDate , setEndDisplayDate] = useState<any>(null)
    const [headers,setHeaders] = useState<HeadersInit>()
    const [searchParams] = useSearchParams();
    const { propertyId, companyId } = useParams();
    const did = "9ae33a7d0ecb82cae8f04aafab20bf90425b7b8c" //"53454wd0ecb82cae8f04aafab20bf90425b7b8c"
    const [isLoading, setIsLoading] = useState(true);
    const [propSetup, setPropSetup] = useState(false)
    const [refresh, setRefresh] = useState<any>(null)
    const [dashboard, setDashboard] = useState<DashBoard>(null)
    const [dashboardData, setDashboardData] = useState<any>([])
    const [property, setProperty] = useState<any>(false)
    const [glTotals, setGLTotals] = useState<any[]>([]);

    

    // 1) Load Property and Headers
    useEffect(() => {
        const setup = async () => {
          setPreviousWeekDates()
          const hdrs = await getHeaders()
          console.log("Headers: ", hdrs)
          setHeaders(hdrs)
          setProperty(await getPropertyDetails(propertyId,companyId,hdrs))
          setPropSetup(true)
        }
        
        setup()
      },[])

    // 2) Get Dashboard components and data
    useEffect(() => {

        const fetchData = async () => {
            setIsLoading(true)
            //setDateFields()
            const hdrs = await getHeaders()
            const db = await getDashboard(hdrs);
            if(db&&startDate){
                console.log("db: ", db)
                setDashboard(db.dashboard);
                setDashboardData(db.dashboardData)
            }
            setIsLoading(false)
        };

        fetchData();
    }, [refresh, propSetup]);

    // Load the dashboard components

    const getDashboard = async (hdrs: HeadersInit): Promise<any>=> {
        //Check if the data is in the cache
        const cacheKey = getCacheKey(did, startDate, endDate)
        const keyExists = cache.hasOwnProperty(cacheKey);
        

        //console.log("searchCache: ",cache )
        if(keyExists){
            console.log("Hash used")
            return cache[cacheKey]
            //return dashboardCache[hashIndex].data
        } else {
            try{
                const body = JSON.stringify({
                    "start": startDate.toISOString().split('T')[0],
                    "end": endDate.toISOString().split('T')[0],
                    "pid": propertyId,
                    "did": did,
                    "rooms": property.rooms,
                    "ara": "4-2400"
                });

               const response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard`, {
                    mode: 'cors',
                    method: 'post',
                    body: body,
                    headers: hdrs
                })
        
                const data = await response.json();
                cache[cacheKey] = data;
                console.log("cache: ", cache)
                return data
        
            } catch (error) {
              console.log("Error retreiving Dashboard", error)
            }
        }
    }


    const setDateRange = (value: string) =>  {
        //setDateRangeValue(value);
        switch (value) {
            case '3 Months':
              setSalesDates(90)
              break;
            case 'Last 12 Months':
              setSalesDates(365)
              break;
            case 'Last 6 Months':
              setSalesDates(183)
              break;
            case '30 Days':
              setSalesDates(30)
              break;
            case '7 Days':
                setSalesDates(7)
                break;
            case 'Last Full Week':
                setPreviousWeekDates();
                break;
            case 'Last Month':
                setLastMonthDates();
                break                
            default:
                setSalesDates(7)
          }

      };

      //Extract the start and end dates when changing the Sales dropdown
    function setSalesDates(priorDays: number, end?: Date): void{

        const endDate = end ? dayjs(end) : dayjs();
        
        let priorDate = endDate.subtract(priorDays, 'd') //new Date(new Date().setDate(endDate.getDate() - priorDays));
        //console.log("setSalesDates: ", `${priorDate.toISOString()} - ${endDate.toISOString()}`)
        
        setStartDate(priorDate.toDate());
        setEndDate(endDate.toDate());
        
        setStartDisplayDate(priorDate)
        setEndDisplayDate(endDate)
    }

    function setPreviousWeekDates() {
        const today = new Date();
        const previousSunday = new Date(today);
        previousSunday.setDate(today.getDate() - today.getDay());
        setSalesDates(7, previousSunday)
    }

    function setLastMonthDates() {
        const today = new Date();
        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      
        const lastDayOfLastMonth = new Date(firstDayOfCurrentMonth);
        lastDayOfLastMonth.setDate(firstDayOfCurrentMonth.getDate() - 1);
      
        const firstDayOfLastMonth = new Date(lastDayOfLastMonth);
        firstDayOfLastMonth.setDate(1);

        console.log('Start Date:', firstDayOfLastMonth.toISOString());
        console.log('End Date:', lastDayOfLastMonth.toISOString());
      
        setStartDate(firstDayOfLastMonth)
        setEndDate(lastDayOfLastMonth)
        //setDateFields()
      }

    // function setDateFields(){
    //     const fromDateInput = document.getElementById("fromDate") as HTMLInputElement;
    //     if (fromDateInput) {
    //       fromDateInput.value = formatDate(startDate)
    //     }
    //     const toDateInput = document.getElementById("toDate") as HTMLInputElement;
    //     if (toDateInput) {
    //         toDateInput.value = formatDate(endDate)
    //     }
    // }

    // Format the dates in the upper input fields
    const formatDate = (isoString: string): string => {
        const date = new Date(isoString);
        const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
        });
        return formattedDate;
    };

    function setManualDates() {
        setStartDate(startDisplayDate)
        setEndDate(endDisplayDate)
        setRefresh(new Date())
    }

    // function parseDate(dateString: string) {
    //     const parts = dateString.split('/');
    //     if (parts.length !== 3) {
    //       throw new Error('Invalid date format');
    //     }
      
    //     const day = parseInt(parts[0], 10);
    //     const month = parseInt(parts[1], 10) - 1; // Months are 0-based
    //     const year = parseInt(parts[2], 10);
      
    //     return new Date(year, month, day);
    //   }

      function setWeekEnd(newValue: any): void {
        const weekEnd = new Date(newValue)
        setSalesDates(7,weekEnd)
        setRefresh(new Date())
    }

    return(
        <DashboardLayout>
        <DashboardNavbar />
        <Grid container alignItems="center" >
            <Grid item lg={6}>
                <MDBox py={1} >
                    <h2>{property.name}</h2>
                </MDBox>
            </Grid>
            {startDisplayDate!=""&& (
            <Grid item lg={6}>
                <Card>
                <Grid container>
                    
                    <Grid item lg={5}>
                        <MDBox p={2}>

                            <DatePicker 
                              format="DD-MM-YYYY"
                              label="From"
                              value={dayjs(startDisplayDate)}
                              onChange={(newValue) => setStartDisplayDate(newValue)}
                            />

                        </MDBox>
                    </Grid>
                    <Grid item lg={5} >
                        <MDBox p={2}>
                        <DatePicker 
                              format="DD-MM-YYYY"   
                              label="To"
                              value={dayjs(endDisplayDate)}
                              onChange={(newValue) => setWeekEnd(newValue)}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item lg={2} >
                        <MDBox p={2}>
                        <MDButton
                            component={Link}
                            to=""
                            variant="gradient"
                            color="light"
                            fullWidth
                            onClick={() => {
                                setManualDates();
                              }}
                          >
                            GO&nbsp;
                        </MDButton>
                        </MDBox>
                    </Grid>
                </Grid>
                </Card>
            </Grid>
            )}
        </Grid>
        {isLoading ? <LoadingSpinner /> : (
        
        <Grid container alignItems="center">

        {/* Loop through at this level for each DashboardComponentGroup */}
        
            {dashboard && dashboard.ComponentGroups.map((group, index) => (
                <DashboardComponentGrp key={index} group={group} glTotals={glTotals}/>
            ))}

        </Grid>
        )}
        
    </DashboardLayout>
    )   
}

export default DataPage;