/*********** PEAK REVENUE  ***********************/

import { SetStateAction, useContext, useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import SalesTable from "examples/Tables/SalesTable";
import DataTable from "examples/Tables/DataTable";

// Sales dashboard components
import ChannelsChart from "layouts/dashboards/properties/components/ChannelsChart";

// Data
import defaultLineChartData from "layouts/dashboards/sales/data/defaultLineChartData";
import horizontalBarChartData from "layouts/dashboards/sales/data/horizontalBarChartData";
import salesTableData from "layouts/dashboards/sales/data/salesTableData";
import dataTableData from "layouts/dashboards/sales/data/dataTableData";

import { format, setISODay } from "date-fns";
import DefaultDataCard from "examples/Cards/Datacard/DefaultDataCard";
import MDInput from "components/MDInput";
import { useSearchParams } from "react-router-dom";
import getJournalTransChartData from "./data/totalRevenueData";
import { AnyAaaaRecord } from "dns";
import lineChartData from "./data/totalRevenueData";
import {getHeaders} from "../../../auth/authService";
import { FormControl, InputLabel, Select, SelectChangeEvent } from "@mui/material";
import { CostData } from "types/costData";
import PieChart from "./components/NivoPie";
//import { AppContext, UserData} from "lib/appContext";

interface DataType {
  labels: string[];
  datasets: {
    label: string;
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
    data: number[];
  }[];
}



function Properties(): JSX.Element {

  // Retrive Total Sales value from API
  const [totalSales, setTotalSales] = useState<number>(0);

  const initCostData: CostData = {
    "currentTotal":0,
    "previousTotal":0,
    "variance":0
  }
  const [houseKeeping, setHouseKeeping] = useState<CostData>(initCostData)
  const [management, setManagement] = useState<CostData>(initCostData)
  const [accomRevenue, setAccomRevenue] = useState<CostData>(initCostData)

  const [glTotals, setGLTotals] = useState<any>(null)
  const [totalMaint, setTotalMaint] = useState<CostData>(initCostData)
  const [totalMgmt, setTotalMgmt] = useState<number>(0);

  const [totalReservations, setTtlReservations] = useState<number>(0)
  const [totaldays, setTtlDays] = useState<number>(0)
  const [adr, setAdr] = useState<number>(0)
  const [occRate, setOccRate] = useState<number>(0)
  const [revPar, setRevPar] = useState<number>(0)
  const [arPar, setArPar] = useState<number>(0)
  const [sumBySourceName, setSumBySourceName] = useState<any>(null)

  const [startDate , setStartDate] = useState<string>("2023-06-01")
  const [endDate , setEndDate] = useState<string>("2023-06-30")
  const [priorStartDate, setPriorStartDate] = useState<string>("2023-05-01")
  const [propertyID, setPropertyID] = useState<number>()
  const [glCode, setGLCode] = useState<string>("4-")

  const [searchParams] = useSearchParams();
  

  const [property, setProperty] = useState<any>(false)
  const tmp = { "one": 1, "two": 2, "three": 3 };
  const [totalRevenueChartData, setTotalRevenueChartData] = useState(defaultLineChartData)
  const [headers,setHeaders] = useState<HeadersInit>()
  //const [user,setUser] = useState<UserData>()

  //const appContext = useContext(AppContext);
  //const userData: UserData | undefined = appContext?.userData;
  //setUser(userData)
  const pid = searchParams.get("propertyId")
  const cid = searchParams.get("companyId")
  const [isLoading, setIsLoading] = useState(true);
  

  //Retrieve Property Details from API
  useEffect(() => {
    
    const setup =async () => {
      setSalesDates(7)
      const hdrs = await getHeaders()
      setHeaders(hdrs)
      await getPropertyDetails(pid,cid,hdrs)
    }
    setIsLoading(true)
    setup()
    setIsLoading(false)
  },[])
  
  //Populate Chart Data
  useEffect(() => {
    const fetchMonthlyData = async () => {
      try {
        const response = await lineChartData(pid, endDate, glCode, headers);
        setTotalRevenueChartData(response);
        //console.log("linechart data:", response)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (!isLoading){
      fetchMonthlyData();
    }
    
  }, [isLoading, endDate, glCode]);

  const getPropertyDetails = async (propertyID:string, companyID:string, hdrs: HeadersInit) => {

    try {
      const queryParams = `?propertyId=${propertyID}&companyId=${companyID}`
      const response: any  = await fetch(`${process.env.REACT_APP_API_URL}/property${queryParams}`, { 
        mode: 'cors',
        method: 'get',
        headers: hdrs,
      })
      const data = await response.json();
      setProperty(data)

    } catch (error) {
      console.log("Error retreiving Property", error)
    }
  }

  // Retrieve the totals for sales from API each time dates are changed
  useEffect(() => {
    if (!isLoading){
      fetchTotals()
    }
  }, [isLoading, startDate, endDate, glCode]);


  // Method to consolidate all GLtotals into a single call
  const getGLTotals = async (startDate: string, endDate: string, isCredit: string, accountIDs: string[]): Promise<number> => {

    const body = JSON.stringify({
      "fromDate": startDate,
      "toDate": endDate,
      "clientID": pid,
      "isCredit": isCredit,
      "accountId": accountIDs
    });

    console.log("getGLTotals Body:", body)
  
    try {
      const fetchedPost = await fetch(`${process.env.REACT_APP_API_URL}/myob/GetMYOBAccountSummaries`, {
        mode: 'cors',
        method: 'post',
        body: body,
        headers: headers,
      });
      
      const data = await fetchedPost.json();
      setGLTotals(data)

      return 1
    } catch (error) {
      console.error("Error:", error);
      return 0;
    }
  };

  useEffect(() => {
    setAdr(accomRevenue.currentTotal/(parseInt(dateRangeValue)*30))
  }, [accomRevenue])

  useEffect(() => {
    setOccRate(totaldays/(parseInt(dateRangeValue)*30))
  }, [totaldays])

  useEffect(() => {
    setRevPar(adr*occRate)
    const apRev = (accomRevenue.currentTotal-houseKeeping.currentTotal)/(parseInt(dateRangeValue)*30)
    setArPar(apRev*occRate)
  }, [adr,occRate])

  useEffect(() => {
    // Your logic that depends on updated glTotals
    if (glTotals) {
      console.log('glTotals updated:', glTotals);
      setHouseKeeping(getAccount("6-6230"))
      setManagement(getAccount("6-6215"))
      setTotalMaint(getAccount("6-6220"))
      setAccomRevenue(getAccount("4-2400"))
    }
  }, [glTotals]);

    // Method to pull Reservation Data from RMS
    const getReservations = async (startDate: string, endDate: string): Promise<number> => {

      const body = JSON.stringify({
        "fromDate": startDate,
        "toDate": endDate,
        "propertyID": 1,
      });
    
      try {
        const fetchedPost = await fetch(`${process.env.REACT_APP_API_URL}/rms/reservations`, {
          mode: 'cors',
          method: 'post',
          body: body,
          headers: headers,
        });
        
        const data = await fetchedPost.json();
        console.log("Reservations ", data)
        setTtlReservations(data.resCount)
        setTtlDays(data.totalDays)
        setSumBySourceName(data.sumBySourceName)

        return 1
      } catch (error) {
        console.error("Error:", error);
        return 0;
      }
    };
  
  const fetchTotals = async () => {
    await getGLTotals(startDate, endDate, "false", ["4-2400","6-6215","6-6220","6-6230"])
    await getReservations(startDate, endDate)
  };
  
  // DefaultStatisticsCard state for the dropdown value
  //const defaultDate = format(new Date(), 'd/m/yyyy');
  const [salesDropdownValue, setSalesDropdownValue] = useState<string>("Last 30 Days");
  const [customersDropdownValue, setCustomersDropdownValue] = useState<string>("Last 30 Days");
  const [revenueDropdownValue, setRevenueDropdownValue] = useState<string>("Last 30 Days");
  const [dateRangeValue, setDateRangeValue] = useState<string>("7");
  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState<string | null>(null);
  const [customersDropdown, setCustomersDropdown] = useState<string | null>(null);
  const [revenueDropdown, setRevenueDropdown] = useState<string | null>(null);

  //Extract the start and end dates when changing the Sales dropdown
  function setSalesDates(priorDays: number): void{
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - priorDays)).toISOString();
    //var priorPeriodStart = new Date(new Date().setDate(today.getDate() - (priorDays*2))).toISOString();
    setStartDate(priorDate);
    //setPriorStartDate(priorPeriodStart)
    setEndDate(today.toISOString());
  }

  const dollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  // Format the dates in the upper input fields
  const formatDate = (isoString: string): string => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    });
    return formattedDate;
  };

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }: any) => setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }: any) => {

    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.value || salesDropdownValue);
    //Extract Start and End Dates
    //console.log(currentTarget.innerText)
    switch (currentTarget.value) {
      case 'Last 3 Years':
        setSalesDates(365*3)
        break;
      case 'Last 12 Months':
        setSalesDates(365)
        break;
      case 'Last 6 Months':
        setSalesDates(183)
        break;
      case 'Last 3 Months':
        setSalesDates(90)
        break;
      case 'Last 7 Days':
          setSalesDates(7)
          break;
      default:
        console.log(`Sorry, we are out of.`);
    }
  };

  const setDateRange = (event: SelectChangeEvent<string>) =>  {

    setSalesDropdown(null);
    setDateRangeValue(event.target.value as string|| dateRangeValue);

    setSalesDates(parseInt(event.target.value))
 
  };
  const openCustomersDropdown = ({ currentTarget }: any) => setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }: any) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openRevenueDropdown = ({ currentTarget }: any) => setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({ currentTarget }: any) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  };

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state: any, close: any) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 Days</MenuItem>
      <MenuItem onClick={close}>Last 3 Months</MenuItem>
      <MenuItem onClick={close}>Last 6 Months</MenuItem>
      <MenuItem onClick={close}>Last 12 Months</MenuItem>
    </Menu>
  );
  


  function getAccount(accountID: string): CostData {
    const itemIndex = glTotals.findIndex((item: { accountID: string; }) => item.accountID === accountID);
    
    const ct = Math.abs(glTotals[itemIndex].totals.CurrentTotal)
    const pt = Math.abs(glTotals[itemIndex].totals.PreviousTotal)
    const costData: CostData = {
      currentTotal: ct,
      previousTotal: pt,
      variance: Math.round(
        ((ct - pt) / pt) * 100
      ),
    };
    console.log("costData :", costData)
    return costData;
  }
  
  

  return (
    <DashboardLayout>
      <MDBox py={1}>
        <h2>{property.name}</h2>
      </MDBox>
      <MDBox py={3}>

        <MDBox mb={4}
          color="black"
          bgColor="white"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4} py={0}>
              <MDBox width="100%" textAlign="left" py={0}>
                <MDBox py={0}>
                  <MDTypography
                    variant="caption"
                    color="secondary"
                    fontWeight="regular">
                    Select a Date Range
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <FormControl>
                    <Select value={dateRangeValue} onChange={setDateRange} fullWidth>
                      <MenuItem value="7">Last 7 Days</MenuItem>
                      <MenuItem value="30">Last 30 Days</MenuItem>
                      <MenuItem value="91">Last 3 Months</MenuItem>
                      <MenuItem value="182">Last 6 Months</MenuItem>
                      <MenuItem value="365">Last 12 Months</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>


              </MDBox>

            </Grid>
            <Grid item xs={12} sm={4}>
              <MDInput label="From Date" id="fromDate" 
                defaultValue={startDate} 
                // onBlur={(event: { target: { value: SetStateAction<string>; }; }) => setStartDate(event.target.value)}
                value={formatDate(startDate)}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDInput label="To Date" id="toDate" 
                defaultValue={endDate} 
                //onBlur={(event: { target: { value: SetStateAction<string>; }; }) => setEndDate(event.target.value)}
                value={formatDate(endDate)}
              />
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <MDInput label="GL Code" id="glCode"
                  defaultValue={glCode} 
                  onBlur={(event: { target: { value: SetStateAction<string>; }; }) => setGLCode(event.target.value)}
                  value={glCode}
                />
            </Grid> */}
          </Grid>
                
        </MDBox>

        <MDBox mb={3}>
        <MDTypography
            py={1}
            variant="h5"
            color="secondary"
            fontWeight="bold">
            Revenue
          </MDTypography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
            <DefaultStatisticsCard
                color={`${houseKeeping.variance<0?'error':'success'}`} 
                title="Accomdation (4-4200)"
                count={dollar.format(accomRevenue.currentTotal)}
                percentage={{
                  color: `${accomRevenue.variance<0?'error':'success'}`,
                  value: `${accomRevenue.variance>0?'+':''}${accomRevenue.variance}%`,
                  label: `since last period - ${dollar.format(accomRevenue.previousTotal)}`,
                }}
              />
            </Grid>
 
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Reservations"
                count={totalReservations}
                percentage={{
                  color: "success",
                  value: `0`,
                  label: "since last month",
                }}
                // dropdown={{
                //   action: openCustomersDropdown,
                //   menu: renderMenu(customersDropdown, closeCustomersDropdown),
                //   value: customersDropdownValue,
                // }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Accomodation Days"
                count={totaldays}
                percentage={{
                  color: "secondary",
                  value: "+$7",
                  label: "since last month",
                }}
              />
            </Grid>
          </Grid>
        </MDBox>

        {/* Accomodation Performance */}
        <MDBox mb={3}>
          <Card id="accom-perf">
            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h6" fontWeight="medium">
                Accomodation Performance
              </MDTypography>

            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} width="30%">
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    
                    <MDBox ml="left" lineHeight={0} color="white">
                      <MDTypography variant="h6" fontWeight="medium">
                        Average Daily Revenue (ADR)
                      </MDTypography>
                    </MDBox>
                    <MDBox ml="auto" lineHeight={0} color="white">
                      <MDTypography variant="h6" fontWeight="medium">
                        {dollar.format(adr)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    
                    <MDBox ml="left" lineHeight={0} color="white">
                      <MDTypography variant="h6" fontWeight="medium">
                        Occupancy Rate
                      </MDTypography>
                    </MDBox>
                    <MDBox ml="auto" lineHeight={0} color="white">
                      <MDTypography variant="h6" fontWeight="medium">
                        {Math.floor(occRate*100)}%
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    
                    <MDBox ml="left" lineHeight={0} color="white">
                      <MDTypography variant="h6" fontWeight="medium">
                        RevPAR
                      </MDTypography>
                    </MDBox>
                    <MDBox ml="auto" lineHeight={0} color="white">
                      <MDTypography variant="h6" fontWeight="medium">
                        {dollar.format(revPar)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    
                    <MDBox ml="left" lineHeight={0} color="white">
                      <MDTypography variant="h6" fontWeight="medium">
                        ArPAR
                      </MDTypography>
                    </MDBox>
                    <MDBox ml="auto" lineHeight={0} color="white">
                      <MDTypography variant="h6" fontWeight="medium">
                        {dollar.format(arPar)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>

        <MDBox mb={3}>
          <MDTypography
            py={1}
            variant="h5"
            color="secondary"
            fontWeight="bold">
            Property Costs
          </MDTypography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                color={`${houseKeeping.variance>0?'error':'success'}`} 
                title="Housekeeping"
                count={dollar.format(houseKeeping.currentTotal)}
                percentage={{
                  color: `${houseKeeping.variance>0?'error':'success'}`,
                  value: `${houseKeeping.variance>0?'+':''}${houseKeeping.variance}%`,
                  label: `since last period - ${dollar.format(houseKeeping.previousTotal)}`,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Maintenance"
                count={dollar.format(totalMaint.currentTotal)}
                percentage={{
                  color: "success",
                  value: "+12%",
                  label: "since last month",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
            <DefaultStatisticsCard
                color={`${management.variance>0?'error':'success'}`} 
                title="Relief Management"
                count={dollar.format(management.currentTotal)}
                percentage={{
                  color: `${management.variance>0?'error':'success'}`,
                  value: `${management.variance>0?'+':''}${management.variance}%`,
                  label: `since last period - ${dollar.format(management.previousTotal)}`,
                }}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              {sumBySourceName && (
              // <ChannelsChart
              //  channelData={sumBySourceName}
              // />  
                <PieChart channelData={sumBySourceName} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
            <DefaultLineChart
              title="Total Revenue"
              description={
                <MDBox display="flex" justifyContent="space-between">
                  <MDBox display="flex" ml={-1}>
                    <MDBadgeDot color="info" size="sm" badgeContent="Received" />
                    <MDBadgeDot color="dark" size="sm" badgeContent="Baseline" />
                  </MDBox>
                  <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                    <Tooltip title="See which ads perform better" placement="left" arrow>
                      <MDButton
                        variant="outlined"
                        color="dark"
                        size="small"
                        circular
                        iconOnly
                      >
                        <Icon>priority_high</Icon>
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              } 
              chart={totalRevenueChartData}
            />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <HorizontalBarChart title="Sales by age" chart={horizontalBarChartData} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <SalesTable title="Sales by Country" rows={salesTableData} />
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                  Top Selling Products
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
                <DataTable
                  table={dataTableData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  
  );
}

export default Properties;
