import React from 'react';
import { ResponsivePie, MayHaveLabel } from '@nivo/pie';
import Card from "@mui/material/Card";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { Grid } from '@mui/material';


interface PieChartData {
  id: string;
  value: number;
}

interface PieChartProps {
  channelData: Record<string, number>;
}




const PieChart: React.FC<PieChartProps> = ({ channelData }) => {
  
  const channelDataArray = Object.entries(channelData);
  channelDataArray.sort((a, b) => a[0].localeCompare(b[0]));
  const sortedChannelData = Object.fromEntries(channelDataArray);

  const data: MayHaveLabel[] = Object.entries(sortedChannelData).map(([sourceName, badgeContent],index) => ({
    id: sourceName,
    label: sourceName.substring(0, 10),
    value: badgeContent
  }));

  return (
    <Card sx={{ height: "100%" }}>
    <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
      <MDTypography variant="h6">Booking Channels</MDTypography>
    </MDBox>
    {/* 
    <MDBox mt={3}>
      <Grid container alignItems="center">
        <Grid item lg={7}> */}
        <div style={{ height: '400px' }}>

          <ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.3}
            startAngle={-17}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme: 'nivo' }} // You can use a different color scheme here
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor="#333333"
            sortByValue={false}
            legends={[
              {
                  anchor: 'bottom',
                  direction: 'row',
                  justify: false,
                  translateX: 15,
                  translateY: 64,
                  itemWidth: 65,
                  itemHeight: 10,
                  itemsSpacing: 0,
                  symbolSize: 14,
                  itemDirection: 'left-to-right'
              }
          ]}
          />
          </div>
        {/* </Grid>
      </Grid>
    </MDBox> */}
  </Card>

  );
};

export default PieChart;
