/**
PEAK REVEUNE
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { ReactHTMLElement, useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import Link from "@mui/material/Link";

import { getHeaders } from "auth/authService";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { ForkLeft, Padding } from "@mui/icons-material";
import container from "assets/theme/components/container";



const ComponentHistory: React.FC = () => {  

  const [chartData, setChartData] = useState<any>([])
  const [companyID, setCompanyID] = useState<any>(null)
  const [error, setError] = useState<string>(null)
  const [component, setComponent] = useState<any>(null)
  const [property, setProperty] = useState<any>(null)
  const [historyButtonText, setHistoryButtonText] = useState("Refresh History")
  const navigate = useNavigate();
  const [max, setMax] = useState<number>(0)
  const [min, setMin] = useState<number>(0)
  const [average, setAverage] = useState<number>(0)
  const { componentId: componentId, propertyId: propertyId} = useParams();

  // function convertChartData(data: any): any[] {
  //   return [{
  //     id: "Chart",
  //     data: data.map((dataPoint: any) => {
  //       return {
  //         x: formatChartDate(dataPoint.EndDate),
  //         y: parseFloat(dataPoint.Value),
  //       };
  //     })}]
  //   };

  function convertChartData(data: any): any[] {
    // Calculate the average, highest and lowest of all y data points
    const total = data.reduce((sum: number, dataPoint: any) => sum + parseFloat(dataPoint.Value), 0);
    const avg = (total / data.length);
    const maximum = Math.max(...data.map((dataPoint: any) => parseFloat(dataPoint.Value)));
    const minimum = Math.min(...data.map((dataPoint: any) => parseFloat(dataPoint.Value)));
    setAverage(avg)
    setMax(maximum)
    setMin(minimum)

    // Create the chart data
    const chartData = data.map((dataPoint: any) => {
      return {
        x: formatChartDate(dataPoint.EndDate),
        y: parseFloat(dataPoint.Value).toFixed(2),
        
      };
    });
  
    // Create the average, highest and lowest line data
    const averageLineData = chartData.map((dataPoint: any) => {
      return {
        x: dataPoint.x,
        y: avg,
      };
    });
  
    const highestLineData = chartData.map((dataPoint: any) => {
      return {
        x: dataPoint.x,
        y: maximum,
      };
    });
  
    const lowestLineData = chartData.map((dataPoint: any) => {
      return {
        x: dataPoint.x,
        y: minimum,
      };
    });
  
    return [
      {
        id: "Actual",
        data: chartData,
      },
      {
        id: "Average",
        data: averageLineData,
      },
      // {
      //   id: "Maximum",
      //   data: highestLineData,
      // },
      // {
      //   id: "Minimum",
      //   data: lowestLineData,
      // },
    ];
  }

  function formatChartDate(date: string): string {
    const d = new Date(date)
    const ld = d.toLocaleDateString('en-AU')
    const day = ld.split("/")[0]
    const month = d.toLocaleString('en-AU', { month: 'short' })
    
    return `${day} ${month}`
    
  }


  useEffect(() => {

    const loadData = async () => {

      try {

        const headers = await getHeaders()
        //Load Property
        const cid = sessionStorage.getItem("company_id")
        const propertyResponse = await fetch(`${process.env.REACT_APP_API_URL}/property?propertyId=${propertyId}&companyId=${cid}`, {
          mode: 'cors',
          method: 'GET', 
          headers: headers,
        })
        const propertyData = await propertyResponse.json();
        console.log("property:", propertyData)
        setProperty(propertyData)

        //Load Component
        // const componentResponse = await fetch(`${process.env.REACT_APP_API_URL}/components/${componentId}`, {
        //   mode: 'cors',
        //   method: 'GET', 
        //   headers: headers,
        // })
        // const componentData = await componentResponse.json();
        // console.log("component:", componentData)
        // setComponent(componentData[0])

        //Load Component History
        const body = JSON.stringify(
          {
            "companyId": sessionStorage.getItem("company_id"),
            "propertyId": propertyId,
            "componentId": componentId,
          })
        const response = await fetch(`${process.env.REACT_APP_API_URL}/gethistory`, {
          mode: 'cors',
          method: 'POST', 
          headers: headers,
          body: body
        })
        const data = await response.json();
        //console.log("history:", data)
        //console.log("component:", data.component[0])
        setComponent(data.component[0])

        if(!data.errors){
          //Process Return Data for all reports
          const chartData = convertChartData(data.history)
          setChartData(chartData)
          //console.log("chartData:", chartData)
          
        }else{
          setError("There was an issue retrieving component History")
          throw new Error(data.errors);
        }
      } catch (error) {
        console.error("Error:", error)
      }
    }

    //if(companyID!==null){
      loadData()
    //}

  }, [])
  
  function formatDates(startDate:string, endDate:string): string{
    const sDate = new Date(startDate)
    const eDate = new Date(endDate)
    return `${sDate.toLocaleDateString()} - ${eDate.toLocaleDateString()}`
  }

  function dataChart()  {
    return (
      <Grid container>
      <Grid item lg={12}>
        <div style={{ height: '320px' }}>
          <ResponsiveLine
            data={chartData}
            
            margin={{ top: 20, right: 20, bottom: 80, left: 50 }}
            xScale={{ type: 'point'}}
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
            curve="catmullRom"
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: `${component&&component.postfix}`,
              legendOffset: -40,
              legendPosition: 'middle',
              //format: (value) => `${value}`
            }}
            enableGridX={true}
            enableGridY={true}
            colors={(d) => {
              switch (d.id) {
                case 'Actual':
                  return 'hsl(10, 50%, 50%)'; // replace with the color for the 'Actual' line
                case 'Average':
                case 'Maximum':
                case 'Minimum':
                  return 'lightgrey';
                default:
                  return 'black';
              }
            }}
            lineWidth={3}
            enablePoints={true}
            enableArea={false}
            areaOpacity={0.2}
            animate={true}
            isInteractive={true}
            pointSize={5}
            pointColor='white'//{{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ theme: 'labels.text.fill' }}
            pointSymbol={(values) => {
              if (values.datum.y !== average) {
                return <circle r={5} fill="white" stroke="black" strokeWidth="2"/>;
              } else {
                return null;
              }
            }}
            enablePointLabel={true}
            pointLabel={e => e.y.valueOf() !== average ? `${component.prefix}${e.y.toString()} ${component.postfix}` : null}
            //pointLabel={point => point.y === 'Chart' ? `$${point.y}` : null}
            pointLabelYOffset={-12}
            enableCrosshair={false}
            useMesh={true}
            
            tooltip={({ point }) => {
              return (
                <div
                  style={{
                    background: 'white',
                    padding: '10px 15px',
                    borderRadius: '2px',
                    transform: 'translateX(-30px)',
                  }}
                >
                  <strong>{point.serieId}</strong>
                  <br />
                  {`Date: ${point.data.x}`}
                  <br />
                  {`Value: ${component.prefix}${point.data.y}`}
                </div>
              );
            }}
            
          />
        </div>
      </Grid>
      </Grid>
    )}


  function refreshHistory(): void {
    
    setHistoryButtonText("Refreshing History...")

    //post componentId, companyId, propertyId, numberOfWeeks to /inithistory API

    const resetHistory = async () => {

      try {

        const headers = await getHeaders()
        
        //refresh Component History
        const body = JSON.stringify(
          {
            "companyId": sessionStorage.getItem("company_id"),
            "propertyId": propertyId,
            "componentId": componentId,
            "numberOfWeeks": 12,
          })
        const response = await fetch(`${process.env.REACT_APP_API_URL}/inithistory`, {
          mode: 'cors',
          method: 'POST', 
          headers: headers,
          body: body
        })
        const data = await response.json();
        console.log("history:", data)

        if(!data.errors){
          //Process Return Data for all reports
          const chartData = convertChartData(data)
          setChartData(chartData)
          console.log("chartData:", chartData)

        }else{
          setError("There was an issue retrieving component History")
          throw new Error(data.errors);
        }
      } catch (error) {
        console.error("Error:", error)
      }
      setHistoryButtonText("Refresh History")
    }

    resetHistory()
  }

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>

        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {component && component.name}
            </MDTypography>
            <MDTypography variant="button" color="text">
              {property && property.name}
            </MDTypography>
          </MDBox>
          <MDBox p={2}>
            <Grid container>
              <Grid item lg={10}>
                {chartData && dataChart()}
              </Grid>
              <Grid item lg={2}>
                <Box style={{ borderRadius: '10px', border: '1px solid #000' }} p={2} marginTop={2}> 
                  <Typography variant="h6" color="textPrimary">Statistics</Typography>
                  <Typography variant="body2" color="textPrimary">Average: {component && component.prefix}{average.toFixed(2)} {component && component.postfix}</Typography>
                  <Typography variant="body2" color="textPrimary">High: {component && component.prefix}{max.toFixed(2)} {component && component.postfix}</Typography>
                  <Typography variant="body2" color="textPrimary">Low: {component && component.prefix}{min.toFixed(2)} {component && component.postfix}</Typography>
                </Box>
              </Grid>
            </Grid>
            
          </MDBox>
          
          <MDBox p={2}>
            <MDButton
              color="dark" 
              size="medium"
              onClick={() => navigate(-1)}
              style={{ marginRight: '4px' }}
            >
              Back to Report
            </MDButton>
            <MDButton
              
              color="warning" 
              size="medium"
              onClick={() => refreshHistory()}
              style={{ float: 'right' }}
            >
              {historyButtonText}
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ComponentHistory;

