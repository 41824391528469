/**
PEAK REVEUNE
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import React, { ReactComponentElement, ReactHTMLElement, useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { Link } from 'react-router-dom';

import { getHeaders } from "auth/authService";
import { AppContext, UserData } from 'lib/appContext';
import { useSearchParams } from "react-router-dom";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MicroChart from "./components/MicroChart"
import { Sparklines } from 'react-sparklines';
import { resourcePerformance } from "./components/HouseKeeperPerf";

const Report: React.FC = () => {  

  type MicroChart = {
    uid: string,
    data: any
  }

  const [searchParams] = useSearchParams();
  const companyID = searchParams.get("cid")
  const reportID = searchParams.get("rid")
  const [report, setReport] = useState<any>(null) 
  const [reportData, setReportData] = useState<any>(null)
  const [microCharts, setMicroCharts] = useState<MicroChart[]>([])

  useEffect(() => {

    const loadReport = async (rid:string, cid:string) => {
      try {
        const headers = await getHeaders()
        const response = await fetch(`${process.env.REACT_APP_API_URL}/reports/view?cid=${cid}&rid=${rid}`, {
          mode: 'cors',
          method: 'GET', 
          headers: headers,
        })
        const data = await response.json();
        console.log("data:", data)
        setReport(data)
        console.log("1")
        //Load micro charts
        const reportData = JSON.parse(data.ReportData)
        console.log("reportData:", reportData)
        const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
        reportData.map((item: any) => {
          item.data?.properties.map(async (property: any) => {
            const loadMicroChart = async (cid:string, pid:string, component_id: string) => {
              const maxRetries = 10;
              for (let i = 0; i < maxRetries; i++) {
                console.log("Micro load:", i)
                try {
                  const body = JSON.stringify({
                    "companyId": cid,
                    "propertyId": pid,
                    "componentId": component_id,
                  });
                  const response = await fetch(`${process.env.REACT_APP_API_URL}/gethistory`, {
                    mode: 'cors',
                    method: 'POST', 
                    headers: headers,
                    body: body
                  });

                  if (response.status === 503) {
                    console.log('Service Unavailable, retrying...');
                    await sleep(1000); // Wait for 1 second
                    continue;
                  }

                  const data = await response.json();

                  return {
                    uid: `${component_id}-${pid}`, 
                    data: data
                  };
                } catch (error) {
                  console.error("Error:", error);
                }
              }
              console.log('Max retries reached, aborting...');
            };
            //item.data.chartData = lo
            if (item.className !== "resourcePerformance") {
              const newMicroChart:MicroChart = await loadMicroChart(companyID, property.propertyId, item.uid);
              setMicroCharts(microCharts => [...microCharts, newMicroChart]);
            }


          })

        })

        console.log(`MicroChart data:`, microCharts)

        if(!data.errors){
          //Process Return Data for the report
          setReportData(JSON.parse(data.ReportData))
          
        }else{
          throw new Error(data.errors);
        }
      } catch (error) {
        console.error("Error:", error)
      }
    }
    
    if(report==null){
    loadReport(reportID, companyID)}

  }, [])

  function convertChartData(data: any): any {
    //console.log("ChartData:", data)
    const returnData = data
      .filter((dataPoint: any) => dataPoint && dataPoint.data) // Filter out null dataPoints and dataPoints with null data
      .map((dataPoint: any) => {
      return {
        id: dataPoint.id,
        data: Object.entries(dataPoint.data).map(([date, value]: [string, unknown]) => {
          if (!date) {
            return null;
          }
          return {
            x: new Date(date),
            y: parseFloat(value as string).toFixed(2),
          };
        }).filter((point: any) => point !== null),
      };
    }).filter((dataPoint: any) => dataPoint !== null);
  
    return returnData;
  }


 

  function dataTable(data: any[], prefix:string, postfix:string, componentId: string)  {
    //console.log("table data:", data)
    return (
      <TableContainer >
        <Table >

          <TableBody>
          <TableRow >
            <TableCell ><Typography variant={"h6"}>Property</Typography></TableCell>
            <TableCell ><Typography variant={"h6"}>Current Period</Typography></TableCell>
            {/* {data[0]?.CurrentAverage !== undefined && data[0]?.CurrentAverage !== null && (
              <TableCell ><Typography variant={"h6"}>Current Average</Typography></TableCell>
            )} */}
            <TableCell ><Typography variant={"h6"}>Previous Period</Typography></TableCell>
            {/* {data[0]?.PreviousAverage !== undefined && data[0]?.PreviousAverage !== null && (
              <TableCell ><Typography variant={"h6"}>Previous Average</Typography></TableCell>
            )} */}
            <TableCell ><Typography variant={"h6"}>History</Typography></TableCell>
          </TableRow>
            {data.map((dataItem) => {
              const foundMicroChart = microCharts.findIndex(microChart => 
                microChart.uid === `${componentId}-${dataItem.propertyId}`
              );
              return (
              <TableRow key={dataItem.ID}>
                <TableCell ><Link  to={`/reports/view/${componentId}/${dataItem.propertyId}`} color="Grey">{dataItem.ID}</Link></TableCell>
                <TableCell >{prefix}{parseFloat(dataItem.CurrentTotal).toLocaleString('en-US', { minimumFractionDigits: 2 })} {postfix}</TableCell>
                {/* {data[0]?.CurrentAverage !== undefined && data[0]?.CurrentAverage !== null && (
                  <TableCell >{prefix}{parseFloat(dataItem.CurrentAverage).toLocaleString('en-US', { minimumFractionDigits: 2 })} {postfix}</TableCell>
                )} */}
                <TableCell >{prefix}{parseFloat(dataItem.PreviousTotal).toLocaleString('en-US', { minimumFractionDigits: 2 })} {postfix}</TableCell>
                {/* {data[0]?.PreviousAverage !== undefined && data[0]?.PreviousAverage !== null && (
                  <TableCell >{prefix}{parseFloat(dataItem.PreviousAverage).toLocaleString('en-US', { minimumFractionDigits: 2 })} {postfix}</TableCell>
                )} */}

                <TableCell style={{ width: '25%' }}>{foundMicroChart > -1 && <MicroChart {...microCharts[foundMicroChart]} />}</TableCell>
              </TableRow>
              )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function dataChart(data: any[])  {
    //console.log("chart data:", data)
    if(data==null){
      return null
    }

    return (
      <>
      <Grid item lg={12}>
        <div style={{ height: '320px' }}>
          
          <ResponsiveLine
            data={convertChartData(data)}
            margin={{ top: 20, right: 20, bottom: 80, left: 50 }}
            xScale={{ type: 'time', format: '%d-%m-%Y', useUTC: true }}
            xFormat="time:%Y-%m-%d"
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
            curve="catmullRom"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              format: '%b %d',
              tickValues: 'every 2 days',
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Legend",
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            enableGridX={true}
            enableGridY={true}
            colors={{ scheme: 'category10' }}
            lineWidth={3}
            enablePoints={true}
            enableArea={false}
            areaOpacity={0.2}
            animate={true}
            isInteractive={true}
            pointSize={5}
            pointColor='white'//{{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ theme: 'labels.text.fill' }}
            pointLabelYOffset={-12}
            enableCrosshair={false}
            useMesh={true}
            pointLabel='x'
            legends={[
              {
                  anchor: 'bottom',
                  direction: 'row',
                  justify: false,
                  translateX: 23,
                  translateY: 60,
                  itemsSpacing: 80,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                      {
                          on: 'hover',
                          style: {
                              itemBackground: 'rgba(0, 0, 0, .03)',
                              itemOpacity: 1
                          }
                      }
                  ],
                  onClick: (data, event) => {alert(data.label)}

              }
          ]}
          />
        </div>
      </Grid>
      </>
    )}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3} >
        <Grid item xs={12} md={12} lg={12}>
          <Card >
            <MDBox p={3} lineHeight={1}>
              <Grid container>
                <Grid item lg={4}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {report && report.Description}
                  </MDTypography>
                </Grid>
                <Grid item lg={4}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Start Date:&nbsp;
                    {report && new Date(report.StartDate).toLocaleDateString()}
                  </MDTypography>
                </Grid>
                <Grid item lg={4}>
                  <MDTypography variant="h6" fontWeight="medium">
                    End Date:&nbsp;
                    {report && new Date(report.EndDate).toLocaleDateString()}
                  </MDTypography>
                </Grid>
              </Grid>

            </MDBox>

          </Card>
        </Grid>

        {/* grid construct for the report data */}
         <Grid container p={3}>
           
          {/* loop through reportData and display a table for each item */}
          {reportData && reportData.map((item: any) => {
            //console.log("item:", item)
            return (
              <Box mb={2} px={1} width={'100%'} key={item.uid}>
              <Card>
              <MDBox p={2} py={3}>
                <Grid container >
                  <Grid item lg={12}>
                    <Grid container>
                      <Grid item lg={12}>
                        <MDBox textAlign="left" lineHeight={1.25} alignItems="left">
                          <MDTypography variant="button" fontWeight="bold" color="text">
                            <h3>{item.name}</h3>
                          </MDTypography>
        
                        </MDBox>
                      </Grid>
                      <Grid item lg={12} md={12}>
                        {
                          item.className === "resourcePerformance"
                            ? resourcePerformance(item.data?.properties)
                            : dataTable(item.data?.properties, item.prefix, item.postfix, item.uid)
                        }
                      </Grid>
                    </Grid>

                  </Grid>
                  {/* {dataChart(item.data.chartData)} */}
                </Grid>
              </MDBox>
            </Card>
            </Box>
            );
          })}
         </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Report;

