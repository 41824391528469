/**
 * Page to select the RMS Property to associate with the Peak Revenue Property
 * 
 * 1. Display a Form to capture RMS ClientId and Password
 * 2. Retreive an Auth token from RMS or Error if can't authenticate due to bad ClientID/Password
 * 3. On success Save RMS Integration against PR Property ID with ClientID and Password
 * 4. Retrive list of RMS Properties for the Client
 * 5. Present a form listing RMS Properties associated with the Client
 * 6. Select an RMS Property
 * 7. Update integration_rms table with RMS PropertyID against the PR PropertyID
 * 8. return to Edit property Table
 */

import { SetStateAction, useContext, useEffect, useState } from "react";
import { Link, Route, useNavigate, Router, Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDAvatar from "components/MDAvatar";
import logoMyob from 'assets/images/logos/myob-app.png';
import { CardContent, Divider, Grid, TextField, Typography } from "@mui/material";
import ItemSelectionForm from "callbacks/rms/Properties";
import { getHeaders } from "auth/authService";
import { AppContext, UserData } from 'lib/appContext';
import { Field, Form, Formik } from "formik";
import MDButton from "components/MDButton";
//import { rmsPropData } from "lib/rmsPropData";


const RMSApiCallback = (): JSX.Element => {

  const appContext = useContext(AppContext);
  const userData: UserData | undefined = appContext?.userData;
  const [headers,setHeaders] = useState<HeadersInit>()
  const [propertyData, setPropertyData] = useState<any>(null)
  const [rmsClientID, setRmsClientID] = useState<any>("")
  const [rmsPassword, setRmsPassword] = useState<any>("")
  const [token, setToken] = useState<string>("")
  const [tokenExpiry, setTokenExpiryDate] = useState<string>("")
  const [rmsPropertyID, setRmsPropertyID] = useState<string>("")
  const [propertyID, setPropertyID] = useState<string>("")

  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
 
  useEffect(() => {

    const fetchData = async () => {
     
      try {
        const hdrs = await getHeaders()
        setHeaders(hdrs)
        
      } catch (error) {
        console.error('Load Error', error);
        return error;
      }
    };
  
    fetchData(); 
  }, []);

  const handleSubmit = async (selectedItem: any) => {
    // Save selected property and token to DB
    //console.log("SelectedItem: ", selectedItem)
    setRmsPropertyID(selectedItem.selectedOption)
    try {
      //alert(`${propertyID} ${rmsPropertyID} ${rmsClientID} ${rmsPassword} ${token}`)

      const body = JSON.stringify({
        "clientId": rmsClientID, 
        "PRPropertyId": propertyID, 
        "RMSPropertyId": selectedItem.selectedOption, 
        "expiryDate": tokenExpiry, 
        "clientPassword": rmsPassword,
        "token": token
      });

      console.log("Body:", body)

      const response = await fetch(`${process.env.REACT_APP_API_URL}/rms/save-integration`, {
          mode: 'cors',
          method: 'post',
          body: body,
          headers: headers,
      })

      const data = await response.json();
      //console.log("saveRMSIntegration Success: ", data )

      //return to properties page
      navigate(`/properties/edit-property?propertyId=${propertyID}&companyId=${sessionStorage.getItem("company_id")}`);

    } catch (error) {
      console.log("saveRMSIntegration Error: ", error )
    }
   
    
  };

  //Generate an Auth token with RMS and retrive property list
  function rmsAuth() {

    try{
      setPropertyID(urlParams.get('propertyId'))
      //retrieve Auth token
      const authenticateRMS = async () => {

        //check for values in clientID and Password
        const clientID = document.getElementById('clientID') as HTMLInputElement;
        const password = document.getElementById('clientPassword') as HTMLInputElement;
        if(clientID.value == "" || password.value == ""){
          throw new Error("RMS Client ID and Password are required");
        }else{
          setRmsClientID(clientID.value)
          setRmsPassword(password.value)
        }

        const body = JSON.stringify({
          "clientId": clientID.value,
          "clientPassword": password.value,
          "token": token
        });

       const response = await fetch(`${process.env.REACT_APP_API_URL}/rms/initiate-integration`, {
            mode: 'cors',
            method: 'post',
            body: body,
            headers: headers,
        })

        const data = await response.json();

        //TODO: Check for error response
        console.log("fetchUrl:", data);

        //Validate response and display property List
        if(data.token){
          setPropertyData(data.propertiesList)
          setToken(data.token)
          setTokenExpiryDate(data.expiryDate)
        }
      }
      
      authenticateRMS()
      
    }catch(error){
      console.log("Error authenticating with RMS: ", error )
    }
  }

 

  

  return (
    <DashboardLayout>
      <Card >
        <CardContent>
          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item lg={2}>
                <MDBox display="flex" alignItems="center">
                  <MDAvatar src={logoMyob} alt="rms logo" variant="rounded" />
                </MDBox>
              </Grid>
              <Grid item lg={10}>
                <MDTypography color="dark" my={1}>
                  Please enter your RMS Client ID and Password to authenticate
                </MDTypography>
              </Grid>
              <Grid item lg={4}>
                <MDBox p={2}>
                  <TextField
                    required
                    id="clientID"
                    label="Client ID"
                    //value={rmsClientID}
                    // onBlur={(newValue) => setRmsClientID(newValue)}
                  />
                 </MDBox>
              </Grid>
              <Grid item lg={4}>
                <MDBox p={2}>
                  <TextField
                    required
                    id="clientPassword"
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    //value={rmsPassword}
                    // onChange={(newValue) => setRmsPassword(newValue)}
                  />
                 </MDBox>
              </Grid>
              <Grid item lg={4}>
                <MDBox p={2}>
                  <MDButton
                      component={Link}
                      to=""
                      variant="gradient"
                      color="light"
                      onClick={() => {
                          rmsAuth();
                        }}
                    >
                      Login to RMS
                  </MDButton>
                 </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <Divider/>
          
          <Typography variant="body2" color="textSecondary" component="p">
            Please select the RMS Property you wish to link to Peak Revenue 
          </Typography>
          <Divider/>

          <MDBox>
          <div>
            {propertyData !== null && <ItemSelectionForm items={propertyData} onSubmit={handleSubmit} />}
          </div>
          </MDBox>
        </CardContent>
      </Card>   
    </DashboardLayout>
  );
}

export default RMSApiCallback;
